import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TranslationSetQueryVariables = Types.Exact<{
  uuid: Types.Scalars['String'];
}>;


export type TranslationSetQuery = { __typename?: 'Query', TranslationSet?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null };

export type UpdateTranslationSetMutationVariables = Types.Exact<{
  uuid: Types.Scalars['String'];
  input: Types.TranslationSetInput;
}>;


export type UpdateTranslationSetMutation = { __typename?: 'Mutation', updateTranslationSet: { __typename?: 'TranslationSetMutationResult', success: boolean, translationSet?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null } };


export const TranslationSetDocument = gql`
    query TranslationSet($uuid: String!) {
  TranslationSet(uuid: $uuid) {
    ...TranslationSet
  }
}
    ${TranslationSetFragmentDoc}`;

/**
 * __useTranslationSetQuery__
 *
 * To run a query within a React component, call `useTranslationSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslationSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslationSetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTranslationSetQuery(baseOptions: Apollo.QueryHookOptions<TranslationSetQuery, TranslationSetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranslationSetQuery, TranslationSetQueryVariables>(TranslationSetDocument, options);
      }
export function useTranslationSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranslationSetQuery, TranslationSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranslationSetQuery, TranslationSetQueryVariables>(TranslationSetDocument, options);
        }
export type TranslationSetQueryHookResult = ReturnType<typeof useTranslationSetQuery>;
export type TranslationSetLazyQueryHookResult = ReturnType<typeof useTranslationSetLazyQuery>;
export type TranslationSetQueryResult = Apollo.QueryResult<TranslationSetQuery, TranslationSetQueryVariables>;
export const UpdateTranslationSetDocument = gql`
    mutation updateTranslationSet($uuid: String!, $input: TranslationSetInput!) {
  updateTranslationSet(uuid: $uuid, input: $input) {
    success
    translationSet {
      ...TranslationSet
    }
  }
}
    ${TranslationSetFragmentDoc}`;
export type UpdateTranslationSetMutationFn = Apollo.MutationFunction<UpdateTranslationSetMutation, UpdateTranslationSetMutationVariables>;

/**
 * __useUpdateTranslationSetMutation__
 *
 * To run a mutation, you first call `useUpdateTranslationSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTranslationSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTranslationSetMutation, { data, loading, error }] = useUpdateTranslationSetMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTranslationSetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTranslationSetMutation, UpdateTranslationSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTranslationSetMutation, UpdateTranslationSetMutationVariables>(UpdateTranslationSetDocument, options);
      }
export type UpdateTranslationSetMutationHookResult = ReturnType<typeof useUpdateTranslationSetMutation>;
export type UpdateTranslationSetMutationResult = Apollo.MutationResult<UpdateTranslationSetMutation>;
export type UpdateTranslationSetMutationOptions = Apollo.BaseMutationOptions<UpdateTranslationSetMutation, UpdateTranslationSetMutationVariables>;