import { UserSelectionInput } from "@src/types.generated";
import { FC, useMemo } from "react";
import useUserSelectionPreviewState from "./hooks/useUserSelectionPreviewState";
import ButtonWithPreview from "@src/deprecatedDesignSystem/components/ButtonWithPreview";
import UserSelectionPreview from "./UserSelectionPreview";

type Props = {
  input: UserSelectionInput;
  onClick: () => void;
  label: string;
  placeholder: string;
  useLocationSelectionCopy?: boolean;
  dataTestId?: string;
};

const AutomationUserSelectionPreviewButton: FC<Props> = ({
  input,
  onClick,
  label,
  placeholder,
  useLocationSelectionCopy = false,
  dataTestId,
}) => {
  const { hasSelection } = useUserSelectionPreviewState({
    input: input,
    showUserTypeOptions: true,
    useLocationSelectionCopy,
  });
  const labelCopy = useMemo(() => {
    return hasSelection ? label : undefined;
  }, [hasSelection, label]);

  return (
    <ButtonWithPreview
      label={labelCopy}
      leftIcon="bolt"
      placeholder={placeholder}
      onClick={onClick}
      dataTestId={dataTestId}
    >
      {hasSelection && (
        <UserSelectionPreview
          pillColor="purple"
          input={input}
          showUserTypeOptions={true}
          useLocationSelectionCopy={useLocationSelectionCopy}
        />
      )}
    </ButtonWithPreview>
  );
};

export default AutomationUserSelectionPreviewButton;
