import React from "react";
import Modal, {
  ConfirmButtonProps,
  ModalButtonProps,
} from "@components/modals/Modal";
import Text from "@ui/text";
import { pluralize } from "@src/utils/strings";
import LightbulbOutlineIcon from "@src/ui/icons/18px/lightbulb-outline";
import { useRouter } from "next/router";
import { useModal } from "@src/hooks/useModal";

type Props = {
  libraryItemId: string;
  itemType: "course" | "module";
  totalAssignedUsers: number;
  totalUsersWithAccess: number;
};

const ManageAssignedUsersModal: React.FC<Props> = (props) => {
  const router = useRouter();
  const { closeModal } = useModal();
  const cancelButtonProps: ModalButtonProps = {
    copy: "Close",
    onClick: () => {
      closeModal();
    },
  };
  const confirmButtonProps: ConfirmButtonProps = {
    onClick: () => {
      closeModal();
      router.push({
        pathname: "/library/library-item/[id]",
        query: {
          id: props.libraryItemId,
          mainTab: "manage",
          manageTab: "assignments",
          allUsersSelected: "true",
        },
      });
    },
    copy: "Manage Assigned Users",
  };
  const totalUsersCopy = getTotalUsersCopy(
    props.totalAssignedUsers,
    props.totalUsersWithAccess,
    props.itemType,
  );
  const proTipCopy = getProTipCopy(props.itemType);
  return (
    <Modal
      title=""
      showHeader={false}
      cancelButtonProps={cancelButtonProps}
      confirmButtonProps={confirmButtonProps}
      bodyStyle={{ width: 530 }}
    >
      <div className="flex flex-1 flex-col items-stretch gap-2 p-5">
        <p className="text-center text-[36px]">🎉</p>
        <Text
          type="P1"
          fontWeight="SemiBold"
          className="mb-3 px-8 text-center text-foreground"
          multiline
        >
          {totalUsersCopy}
        </Text>
        <div className="flex gap-1 rounded-lg bg-gray-1 p-3">
          <LightbulbOutlineIcon />
          <Text type="P3" className="text-foreground" multiline>
            <span className="font-bold">Pro tip:</span> {proTipCopy}
          </Text>
        </div>
      </div>
    </Modal>
  );
};

function getProTipCopy(itemType: "course" | "module") {
  return itemType === "course"
    ? "You can inform users of changes by messaging or reassigning the course to users who had started older versions."
    : "You can inform users of changes by messaging or reassigning updated content to users who have already been assigned.";
}

function getTotalUsersCopy(
  totalAssignedUsers: number,
  totalUsersWithAccess: number,
  itemType: "course" | "module",
) {
  const assignedUsersCopy = `${totalAssignedUsers} assigned ${pluralize("user", totalAssignedUsers)}`;
  const usersWithAccessCopy = totalUsersWithAccess
    ? `and ${totalUsersWithAccess} ${pluralize("user", totalUsersWithAccess)} with library access`
    : "";
  const nowHaveTheLatestCopy = `now have the latest ${itemType}`;
  return `${assignedUsersCopy} ${usersWithAccessCopy} ${nowHaveTheLatestCopy}`;
}

export default ManageAssignedUsersModal;
