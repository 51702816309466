import { useCallback, useContext, useEffect, useMemo } from "react";
import { ClientIdContext } from "@builder/shared/ClientIdContext";
import { secondsBetweenDates, secondsSinceDate } from "@utils/dates";
import { BuilderActionPartial } from "@contexts/BuilderActionContext";
import { BuilderActionFragment } from "@src/components/libraryItemDetailPages/hooks/usePollServerForBuilderActions.generated";
import { uuid4 } from "@utils/strings";
import { BuilderActionInput } from "@src/types.generated";
import shouldActionsBeDeduped from "@src/components/libraryItemDetailPages/module/utils/shouldActionsBeDeduped";
import {
  ApplyBuilderActionsMutation,
  useApplyBuilderActionsMutation,
} from "@src/components/libraryItemDetailPages/course/build/CourseBuildContent.generated";
import { atom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { focusAtom } from "jotai-optics";

export type Return = {
  addToSyncQueue: (action: BuilderActionPartial) => void;
};

type SyncQueueBuilderAction = BuilderActionPartial &
  Pick<BuilderActionFragment, "uuid" | "clientId">;

const builderSyncBuilderActionsAtom = atom({
  queue: [] as SyncQueueBuilderAction[],
  lastSyncAt: new Date(),
  firstSyncErrorAt: null as Date | null,
  lastSuccessfulSyncAt: new Date(),
});
export const queueAtom = focusAtom(builderSyncBuilderActionsAtom, (optic) =>
  optic.prop("queue"),
);

export const lastSyncAtAtom = focusAtom(
  builderSyncBuilderActionsAtom,
  (optic) => optic.prop("lastSyncAt"),
);
export const firstSyncErrorAtAtom = focusAtom(
  builderSyncBuilderActionsAtom,
  (optic) => optic.prop("firstSyncErrorAt"),
);
export const lastSuccessfulSyncAtAtom = focusAtom(
  builderSyncBuilderActionsAtom,
  (optic) => optic.prop("lastSuccessfulSyncAt"),
);

const useSyncBuilderActionsToServer = (): Return => {
  const clientIdContext = useContext(ClientIdContext);
  const onCompleted = useAtomCallback<
    void,
    [
      {
        applyBuilderActions: ApplyBuilderActionsMutation["applyBuilderActions"];
      },
    ]
  >(
    useCallback((get, set, { applyBuilderActions }) => {
      const firstSyncErrorAt = get(firstSyncErrorAtAtom);
      if (applyBuilderActions.success) {
        set(lastSuccessfulSyncAtAtom, new Date());
        set(firstSyncErrorAtAtom, null);
        const appliedBuilderActions = applyBuilderActions.appliedBuilderActions;
        if (!appliedBuilderActions || appliedBuilderActions.length === 0)
          return;
        const actionUuids = appliedBuilderActions.map((e) => e.uuid);
        set(queueAtom, (prev) =>
          prev.filter((action) => !actionUuids.includes(action.uuid)),
        );
      } else {
        if (!firstSyncErrorAt) {
          set(firstSyncErrorAtAtom, new Date());
        }
      }
    }, []),
  );
  const onError = useAtomCallback(
    useCallback((get, set) => {
      set(lastSyncAtAtom, new Date());
      const firstSyncErrorAt = get(firstSyncErrorAtAtom);
      if (!firstSyncErrorAt) {
        set(firstSyncErrorAtAtom, new Date());
      }
    }, []),
  );

  const [applyBuilderActionsMutation, { loading }] =
    useApplyBuilderActionsMutation({
      onCompleted: (data) => {
        onCompleted({ applyBuilderActions: data?.applyBuilderActions });
      },
      onError() {
        onError();
      },
    });

  const shouldApplyBuilderActions = useAtomCallback(
    useCallback(
      (get) => {
        const queue = get(queueAtom);
        const lastSyncAt = get(lastSyncAtAtom);
        if (queue.length === 0 || loading) return false;
        if (secondsSinceDate(lastSyncAt) <= 1) return false;
        return true;
      },
      [loading],
    ),
  );
  const applyBuilderActionsSync = useAtomCallback(
    useCallback(
      (get, set) => {
        if (shouldApplyBuilderActions()) {
          set(lastSyncAtAtom, new Date());
          const inputs: BuilderActionInput[] = get(queueAtom)
            .slice(0, 10)
            .map(transformQueueItemToBuilderActionInput);
          applyBuilderActionsMutation({
            variables: {
              actions: inputs,
            },
          });
        }
      },
      [applyBuilderActionsMutation, shouldApplyBuilderActions],
    ),
  );
  const syncBuilderActionsOnInterval = useAtomCallback(
    useCallback(
      (get, set) => {
        if (
          !loading &&
          get(queueAtom).length > 0 &&
          secondsSinceDate(get(lastSyncAtAtom)) >= 1 &&
          shouldRetryFailedSync(get(firstSyncErrorAtAtom))
        ) {
          set(lastSyncAtAtom, new Date());
          const inputs: BuilderActionInput[] = get(queueAtom)
            .slice(0, 10)
            .map(transformQueueItemToBuilderActionInput);
          applyBuilderActionsMutation({
            variables: {
              actions: inputs,
            },
          });
        }
      },
      [applyBuilderActionsMutation, loading],
    ),
  );
  useEffect(() => {
    if (shouldApplyBuilderActions()) {
      applyBuilderActionsSync();
    }
  }, [applyBuilderActionsSync, shouldApplyBuilderActions]);
  useEffect(() => {
    const interval = setInterval(() => {
      syncBuilderActionsOnInterval();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [syncBuilderActionsOnInterval]);
  const addToSyncQueue: Return["addToSyncQueue"] = useAtomCallback(
    useCallback(
      (get, set, action) => {
        if (!clientIdContext) return get(queueAtom);
        set(queueAtom, (prev) => [
          ...prev.filter((x) => !shouldActionsBeDeduped(x, action)),
          {
            ...action,
            clientId: clientIdContext.clientId,
            uuid: uuid4(),
          },
        ]);
      },
      [clientIdContext],
    ),
  );
  return useMemo(
    () => ({
      addToSyncQueue,
    }),
    [addToSyncQueue],
  );
};

export default useSyncBuilderActionsToServer;

const transformQueueItemToBuilderActionInput = (
  x: SyncQueueBuilderAction,
): BuilderActionInput => {
  return {
    uuid: x.uuid,
    clientId: x.clientId,
    courseBuilderAction: x.payload.courseBuilderAction,
    pathBuilderAction: x.payload.pathBuilderAction,
    skillBuilderAction: x.payload.skillBuilderAction,
    trainingResourceBuilderAction: x.payload.trainingResourceBuilderAction,
  };
};

export const shouldRetryFailedSync = (
  firstSyncErrorAt: Date | null | undefined,
): boolean => {
  if (!firstSyncErrorAt) return true;
  return secondsBetweenDates(firstSyncErrorAt || new Date(), new Date()) < 50;
};
