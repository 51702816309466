import { useModal } from "@src/hooks/useModal";
import React, { FC } from "react";
import Modal from "./Modal";
import { css, StyleSheet } from "aphrodite";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";

type Props = {
  title: string;
  itemName?: string;
  onArchive: () => void;
  loading?: boolean;
  itemCount?: number;
  includeModuleCopy?: boolean;
};

const ArchiveConfirmationModal: FC<Props> = ({
  title,
  itemName,
  onArchive,
  loading,
  itemCount = 1,
  includeModuleCopy = false,
}) => {
  const { closeModal } = useModal();

  return (
    <Modal
      title={title}
      style={{
        width: 400,
      }}
      footerClassName={css(styles.modalFooter)}
      confirmButtonProps={{
        copy: "Archive",
        onClick: () => {
          onArchive();
          closeModal();
        },
        alert: true,
        loading,
      }}
      cancelButtonProps={{
        copy: "Cancel",
        onClick: () => {
          closeModal();
        },
      }}
    >
      <AutoLayout padding={24}>
        <Text type={"P2"} multiline={true}>
          {itemName && (
            <>
              Are you sure you want to archive <b>{itemName}</b>?
              <br />
              <br />
            </>
          )}
          {itemCount === 1 ? "It" : `${itemCount} items`} will be removed from
          your library, but you'll still be able to find{" "}
          {itemCount === 1 ? "it" : "them"} under the Settings {">"} Archive
          tab.
          {includeModuleCopy && (
            <>
              <br />
              <br />
              If this content is inside a module, it will be removed from the
              module as well.
            </>
          )}
        </Text>
      </AutoLayout>
    </Modal>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 24,
    fontSize: 14,
  },
  modalFooter: {
    borderTop: "none",
  },
  archiveModalText: {
    marginTop: 12,
  },
});

export default ArchiveConfirmationModal;
