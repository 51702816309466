import { Route } from "nextjs-routes";
import { UserContextState } from "@src/contexts/UserContext";
import {
  CoverImage,
  LibraryItemType,
  TrainingResourceVersionType,
  SharingPermission,
  UserType,
} from "@src/types.generated";
import { gql } from "@apollo/client";
import { CoverImageFragment } from "@src/fragments.generated";
import {
  HasLibraryItemEditPermission_LibraryItemFragment,
  LibraryItemOwner_LibraryItemFragment,
  ModuleOrPremiumContentLibraryItemTitle_LibraryItemFragment,
  PremiumLibraryItemUrl_LibraryItemFragment,
  TrainingResourceAvatar_LibraryItemFragment,
} from "@src/components/contentLibrary/utils.generated";
import ContentAvatar, {
  ContentType,
  LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import {
  LibraryContentSharingPermissionFragment,
  LibraryUserSharingPermissionFragment,
} from "@src/components/contentLibrary/utils.generated";
import { ModuleLibraryPaginatedTable_LibraryItemFragment } from "../library/modules/ModulesLibraryPaginatedTable.generated";
import {
  getOrgPremiumContentConfigFragmentStatus,
  getOrgSharpConfigStatus,
} from "@src/utils/premiumPaths";
import { ReactNode, useCallback } from "react";
import useUser from "@src/hooks/useUser";
import PathOverflowMenu from "./PathOverflowMenu";
import useActionPermissions from "@src/hooks/useActionPermissions";
import { PUBLISH_STATE_COPY } from "@src/utils/courses";

export const getCourseDetailRoute = (
  courseId: number,
  courseInstanceId?: number,
  tab?: string,
): Route => {
  const query = {
    id: courseId.toString(),
  };
  if (courseInstanceId) {
    Object.assign(query, { courseInstanceId: courseInstanceId.toString() });
  }
  if (tab) {
    Object.assign(query, { tab });
  }
  return {
    pathname: "/courses/[id]",
    query,
  };
};

export const getPathDetailRoute = (
  pathId: number,
  pathInstanceId?: string,
  tab?: string,
): Route => {
  const query = {
    id: pathId.toString(),
  };
  if (pathInstanceId) {
    Object.assign(query, { pathInstanceId });
  }
  if (tab) {
    Object.assign(query, { tab });
  }
  return {
    pathname: "/paths/[id]",
    query,
  };
};

export const getFileDetailRoute = (fileId: string): Route => {
  return {
    pathname: "/files/[id]",
    query: {
      id: fileId.toString(),
    },
  };
};

export const getSkillDetailRoute = (skillId: number): Route => {
  return {
    pathname: "/skills/[id]",
    query: {
      id: skillId.toString(),
    },
  };
};

export const renderTrainingResourceAvatar = (
  x: TrainingResourceAvatar_LibraryItemFragment,
): ReactNode | undefined => {
  let coverImage: CoverImage | undefined = undefined;
  if (
    x.trainingResource?.draftVersion?.type === TrainingResourceVersionType.Media
  ) {
    coverImage = {
      id: x.trainingResource.id,
      imageUrls: x.trainingResource.draftVersion?.media?.imageUrls,
    };
  } else if (
    x.trainingResource?.draftVersion?.type ===
    TrainingResourceVersionType.RichTextDocument
  ) {
    coverImage = {
      id: x.trainingResource?.id,
      emojiIcon: "📄",
    };
  }
  return (
    <ContentAvatar
      size={"32px"}
      contentType={"Resource"}
      style={{ borderRadius: 8 }}
      media={x.trainingResource?.draftVersion?.media}
      coverImage={coverImage}
      defaultBackgroundHashKey={x.id}
    />
  );
};

export const useRenderModuleOverflowMenu = (): ((
  x: ModuleLibraryPaginatedTable_LibraryItemFragment,
) => ReactNode | undefined) => {
  const userContextState = useUser();
  return useCallback(
    (x: ModuleLibraryPaginatedTable_LibraryItemFragment) => {
      const itemSharingPermissions = x.sharingPermissions;
      const itemOwnerId = getModuleOwnerId(x);
      if (x.orgSharpConfig || x.orgPremiumContentConfig) {
        return null;
      } else if (
        userContextState?.user?.userType === UserType.Admin ||
        (!!itemSharingPermissions &&
          userHasEditPermissions(
            itemSharingPermissions,
            userContextState,
            itemOwnerId,
          ))
      ) {
        return <PathOverflowMenu libraryItem={x} isVisible rotateIcon />;
      } else {
        return null;
      }
    },
    [userContextState],
  );
};

export const renderModuleAvatar = (
  x: ModuleLibraryPaginatedTable_LibraryItemFragment,
): ReactNode | undefined => {
  let id: number | string = -1;
  let coverImage: CoverImage | undefined = {} as CoverImage;
  if (x.orgSharpConfig) {
    id = x.orgSharpConfig.id;
    coverImage = { id } as CoverImage;
  } else if (x.orgPremiumContentConfig) {
    id = x.orgPremiumContentConfig.id;
    coverImage =
      x.orgPremiumContentConfig.premiumContentConfig.catalogPath?.path
        .libraryItem.coverImage || undefined;
  } else {
    id = x.id;
    coverImage = x.coverImage || undefined;
  }
  return (
    <ContentAvatar
      size={"32px"}
      contentType={getLibraryItemContentType(x.type)}
      style={{ borderRadius: 8 }}
      coverImage={coverImage}
      defaultBackgroundHashKey={id}
    />
  );
};

export const renderModulePublishedStatus = (
  x: ModuleLibraryPaginatedTable_LibraryItemFragment,
): string | null => {
  let publishedStatus = null;
  if (x.path) {
    return x.publishState ? PUBLISH_STATE_COPY[x.publishState] : null;
  }
  if (x.orgPremiumContentConfig) {
    publishedStatus = getOrgPremiumContentConfigFragmentStatus(
      x.orgPremiumContentConfig,
    );
  } else if (x.orgSharpConfig) {
    return getOrgSharpConfigStatus(x.orgSharpConfig);
  }
  return publishedStatus;
};

export const getModuleLibraryItemEditedAt = (
  x: ModuleLibraryPaginatedTable_LibraryItemFragment,
): string | undefined => {
  if (x.orgSharpConfig) {
    return x.orgSharpConfig.lastEditedAt || x.orgSharpConfig.createdAt;
  } else if (x.orgPremiumContentConfig) {
    return (
      x.orgPremiumContentConfig.lastEditedAt ??
      x.orgPremiumContentConfig.createdAt
    );
  } else {
    return x.lastEditedAt || x.createdAt;
  }
};

export const getPremiumLibraryItemUrl = (
  item: PremiumLibraryItemUrl_LibraryItemFragment,
): Route | undefined => {
  if (
    item.orgPremiumContentConfig &&
    item.orgPremiumContentConfig.premiumContentConfig.catalogPath?.path.id
  ) {
    return getPremiumPathRoute(
      item.orgPremiumContentConfig.premiumContentConfig.catalogPath
        .id as string,
    );
  } else if (item.orgSharpConfig) {
    return SHARP_DETAIL_ROUTE;
  }
};

gql`
  fragment PremiumLibraryItemUrl_LibraryItem on LibraryItem {
    id
    orgPremiumContentConfig {
      id
      premiumContentConfig {
        id
        catalogPath {
          id
          path {
            id
          }
        }
      }
    }
    orgSharpConfig {
      id
    }
  }
`;

export const SHARP_DETAIL_ROUTE: Route = {
  pathname: "/sexual-harassment-prevention",
};

export const getPremiumPathRoute = (catalogPathId: string): Route => {
  return {
    pathname: "/premium-path/[id]",
    query: {
      id: catalogPathId.toString(),
    },
  };
};

export const getPremiumPathPreviewRoute = (catalogPathId: string): Route => {
  return {
    pathname: "/paths/[id]/preview",
    query: {
      id: catalogPathId.toString(),
    },
  };
};

export const userHasEditPermissions = (
  sharingPermissions: LibraryContentSharingPermissionFragment,
  userContext: UserContextState,
  contentOwnerId?: number | undefined,
): boolean => {
  if (userContext.isStaff) {
    return true;
  }
  const userPermission = sharingPermissions.individuals.find(
    (i: LibraryUserSharingPermissionFragment) =>
      i.user.id === userContext.user?.id,
  );
  if (contentOwnerId && contentOwnerId === userContext.user?.id) {
    return true;
  }
  if (userPermission?.permission === SharingPermission.Edit) {
    return true;
  }
  if (
    userContext.user?.userType == UserType.Admin &&
    sharingPermissions.allAdmins == SharingPermission.Edit
  ) {
    return true;
  }
  if (
    userContext.user?.userType == UserType.Manager &&
    sharingPermissions.allManagers == SharingPermission.Edit
  ) {
    return true;
  }
  return false;
};

export const getModuleOrPremiumContentLibraryItemTitle = (
  x: ModuleOrPremiumContentLibraryItemTitle_LibraryItemFragment,
): string => {
  if (x.type === LibraryItemType.Path) {
    return x.name.en || "Untitled Module";
  } else if (x.orgSharpConfig) {
    return "Sexual Harassment Prevention Training";
  } else if (x.orgPremiumContentConfig) {
    return (
      x.orgPremiumContentConfig.premiumContentConfig.catalogPath?.path
        .libraryItem.name.en || "Untitled Module"
    );
  }
  return "";
};

gql`
  fragment ModuleOrPremiumContentLibraryItemTitle_LibraryItem on LibraryItem {
    id
    name {
      en
    }
    type
    orgSharpConfig {
      id
    }
    orgPremiumContentConfig {
      id
      premiumContentConfig {
        id
        catalogPath {
          id
          path {
            id
            libraryItem {
              id
              name {
                en
              }
            }
          }
        }
      }
    }
  }
`;

type ShouldDisableMultiSelectForItem = (
  sharingPermissions: LibraryContentSharingPermissionFragment,
  ownerUserId: number | undefined,
) => boolean;

export const useShouldDisableMultiSelectForItem =
  (): ShouldDisableMultiSelectForItem => {
    const userContextState = useUser();
    const { userActionPermissions } = useActionPermissions();
    return useCallback(
      (
        sharingPermissions: LibraryContentSharingPermissionFragment,
        ownerUserId: number | undefined,
      ) => {
        if (userActionPermissions?.manageLibrary === false) {
          return true;
        }
        return (
          !!sharingPermissions &&
          !userHasEditPermissions(
            sharingPermissions,
            userContextState,
            ownerUserId,
          )
        );
      },
      [userActionPermissions?.manageLibrary, userContextState],
    );
  };

export const getLibraryItemContentType = (x: LibraryItemType): ContentType => {
  return LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE[x];
};

export const getLibraryItemOwnerName = (
  x: LibraryItemOwner_LibraryItemFragment,
): string | undefined => {
  let owner: string | undefined = undefined;
  if (x.orgPremiumContentConfig) {
    owner = x.orgPremiumContentConfig.createdBy?.name;
  } else if (x.orgSharpConfig) {
    owner = x.orgSharpConfig.createdBy?.name;
  } else {
    owner = x.createdBy?.name;
  }
  return owner;
};

export const getModuleOwnerId = (
  x: LibraryItemOwner_LibraryItemFragment,
): number | undefined => {
  let owner: number | undefined = undefined;
  if (x.orgPremiumContentConfig) {
    owner = x.orgPremiumContentConfig.createdBy?.id;
  } else if (x.orgSharpConfig) {
    owner = x.orgSharpConfig.createdBy?.id;
  } else {
    owner = x.createdBy?.id;
  }
  return owner;
};

type HasLibraryItemEditPermissionFunc = (args: {
  libraryItem: HasLibraryItemEditPermission_LibraryItemFragment;
  contentOwnerUserId?: number;
}) => boolean;

export const useHasLibraryItemEditPermission =
  (): HasLibraryItemEditPermissionFunc => {
    const userContextState = useUser();
    return useCallback(
      (args) => {
        return !!(
          userContextState?.user?.userType === UserType.Admin ||
          (!!args.libraryItem.sharingPermissions &&
            userHasEditPermissions(
              args.libraryItem.sharingPermissions,
              userContextState,
              args.contentOwnerUserId,
            ))
        );
      },
      [userContextState],
    );
  };

gql`
  fragment HasLibraryItemEditPermission_LibraryItem on LibraryItem {
    id
    sharingPermissions {
      ...LibraryContentSharingPermission
    }
  }
`;

gql`
  fragment LibraryItemOwner_LibraryItem on LibraryItem {
    id
    createdBy {
      id
      name
    }
    orgPremiumContentConfig {
      id
      createdBy {
        id
        name
      }
    }
    orgSharpConfig {
      id
      createdBy {
        id
        name
      }
    }
  }
  fragment LibraryItemUrl on LibraryItem {
    id
    publishState
    path {
      id
      publishedVersion {
        id
      }
      draftVersion {
        id
      }
    }
    orgPremiumContentConfig {
      id
      premiumContentConfig {
        id
        catalogPath {
          id
          path {
            id
          }
        }
      }
    }
    course {
      id
    }
    trainingResource {
      id
    }
    orgSharpConfig {
      id
    }
    skill {
      id
    }
  }

  fragment LibraryItemTitle on LibraryItem {
    id
    name {
      en
    }
    type
    orgSharpConfig {
      id
    }
    orgPremiumContentConfig {
      premiumContentConfig {
        catalogPath {
          path {
            libraryItem {
              id
              name {
                en
              }
            }
          }
        }
      }
    }
  }

  fragment LibraryItemAvatar on LibraryItem {
    id
    type
    coverImage {
      ...CoverImage
    }
    trainingResource {
      id
      publishedVersion {
        ...TrainingResourceVersion
      }
      draftVersion {
        ...TrainingResourceVersion
      }
    }
    orgSharpConfig {
      id
    }
    orgPremiumContentConfig {
      id
      premiumContentConfig {
        id
        catalogPath {
          id
          path {
            id
            libraryItem {
              id
              coverImage {
                ...CoverImage
              }
            }
          }
        }
      }
    }
  }

  fragment LibraryItemOwner on LibraryItem {
    createdBy {
      id
      name
    }
  }
  fragment LibraryUserSharingPermission on UserSharingPermission {
    id
    user {
      id
    }
    permission
  }
  fragment LibraryContentSharingPermission on ContentSharingPermission {
    id
    allAdmins
    allManagers
    individuals {
      ...LibraryUserSharingPermission
    }
  }
`;
gql`
  fragment TrainingResourceVersion__Avatar on TrainingResourceVersion {
    id
    type
    media {
      ...Media
    }
  }
  fragment TrainingResourceAvatar_LibraryItem on LibraryItem {
    id
    trainingResource {
      id
      draftVersion {
        ...TrainingResourceVersion__Avatar
      }
      publishedVersion {
        ...TrainingResourceVersion__Avatar
      }
    }
  }
`;

export const hasCoverImage = (
  coverImage: CoverImageFragment | null | undefined,
): boolean => {
  if (!coverImage) return false;
  return (
    !!coverImage.background || !!coverImage.emojiIcon || !!coverImage.imageUrls
  );
};
