import { css, StyleSheet } from "aphrodite";
import { launchConfetti } from "@utils/confetti";
import {
  AUTOMATION_CONTENT_TYPES_WITH_END_DATETIME,
  AUTOMATION_CONTENT_TYPES_WITH_NEW_USERS_ONLY,
  AUTOMATION_CONTENT_TYPES_WITH_START_DATETIME,
  AutomationItem,
} from "@src/components/sidebars/shared/constants";
import {
  DiffAutomationRulesChangeInput,
  UserSelectionInput,
} from "@src/types.generated";
import { useToast } from "@src/hooks/useToast";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { convertLocalDateTimeWithoutTzToUTCStringWithoutTz } from "@src/utils/dates";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import NowOrScheduledPicker from "@src/components/ui/NowOrScheduledPicker";
import Spacer from "@src/components/ui/Spacer";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { pluralize } from "@src/utils/strings";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";
import ToggleButton from "@src/deprecatedDesignSystem/components/ToggleButton";
import Text from "@ui/text";
import useUserSelectionPreviewState from "./useUserSelectionPreviewState";
import CreditsChargeWhenStartedBanner from "../CreditsChargeWhenStartedBanner";
import {
  useDiffAutomationChangeMutation,
  useUpdateAutomationMutation,
} from "@src/components/contentDetail/premiumPathDetail/AutoReassignToggle.generated";
import { OrgPremiumContentConfigFragment } from "@src/components/library/LibraryPaginatedTable.generated";

type Props = {
  automationItem: AutomationItem;
  userSelectionInput: UserSelectionInput;
  refetchQueries: string[];
  orgConfig?: OrgPremiumContentConfigFragment;
  hideSuccessToast?: boolean;
  createAutomationCompleted: () => void;
  warnOnNoLocationsSelectedText?: string;
};

type Return = {
  createAutomation: () => void;
  userSelectionValidationError: string | undefined;
  createAutomationLoading: boolean;
  reviewAutomationDetails: ReactNode;
  clearUserSelectionValidationError: () => void;
};

const useReviewAutomationState = (props: Props): Return => {
  const { hasSelection } = useUserSelectionPreviewState({
    input: props.userSelectionInput,
    showUserTypeOptions: true,
  });
  const { addToast, addErrorToast } = useToast();
  const [userSelectionValidationError, setUserSelectionValidationError] =
    useState<undefined | string>(undefined);
  const [dateValidationError, setDateValidationError] = useState<
    undefined | string
  >(undefined);
  // If these date times are equal, they are not useful to show to users in this step
  const initialStartAndEndDatetimeAreEqual =
    props.automationItem.automation.startDatetime ===
    props.automationItem.automation.endDatetime;
  const [startDatetime, setStartDatetime] = useState<Date | null | undefined>(
    props.automationItem.automation.startDatetime &&
      !initialStartAndEndDatetimeAreEqual
      ? DateTime.fromISO(
          props.automationItem.automation.startDatetime,
        ).toJSDate()
      : undefined,
  );
  const [endDatetime, setEndDatetime] = useState<Date | null | undefined>(
    props.automationItem.automation.endDatetime &&
      !initialStartAndEndDatetimeAreEqual
      ? DateTime.fromISO(props.automationItem.automation.endDatetime).toJSDate()
      : undefined,
  );
  const [numAddedImmediately, setNumAddedImmediately] = useState<
    number | undefined
  >(0);
  const [numScheduledToAdd, setNumScheduledToAdd] = useState<number | null>(
    null,
  );
  const willSendNotifications = useMemo(() => {
    return props.automationItem.__typename !== "TrainingResource";
  }, [props.automationItem.__typename]);
  const [newUsersOnly, setNewUsersOnly] = useState(
    props.automationItem.automation.newUsersOnly,
  );
  const [numExistingUsers, setNumExistingUsers] = useState<number | null>(null);
  const showStartDateSelector =
    AUTOMATION_CONTENT_TYPES_WITH_START_DATETIME.includes(
      props.automationItem.__typename,
    );
  const showEndDateSelector =
    AUTOMATION_CONTENT_TYPES_WITH_END_DATETIME.includes(
      props.automationItem.__typename,
    );
  const showNewUsersOnlyToggle =
    AUTOMATION_CONTENT_TYPES_WITH_NEW_USERS_ONLY.includes(
      props.automationItem.__typename,
    );
  const startDatetimeConvertedFromLocalToUTC = useMemo(() => {
    const _startDatetime = startDatetime || new Date();
    return convertLocalDateTimeWithoutTzToUTCStringWithoutTz(_startDatetime);
  }, [startDatetime]);
  const endDatetimeConvertedFromLocalToUTC = useMemo(() => {
    if (!endDatetime) return null;
    return convertLocalDateTimeWithoutTzToUTCStringWithoutTz(endDatetime);
  }, [endDatetime]);
  const numExistingUsersWhoWillBeAssignedContentAfterAutomationStartDate =
    useMemo(() => {
      if (!numExistingUsers || !startDatetime) return 0;
      if (newUsersOnly) return 0;
      return numExistingUsers;
    }, [newUsersOnly, numExistingUsers, startDatetime]);
  const input: DiffAutomationRulesChangeInput = useMemo(
    () => ({
      automationId: props.automationItem.automation.id,
      automationInput: {
        enabled: { value: true },
        userSelection: props.userSelectionInput,
        startDatetime: AUTOMATION_CONTENT_TYPES_WITH_START_DATETIME.includes(
          props.automationItem.__typename,
        )
          ? { value: startDatetimeConvertedFromLocalToUTC }
          : null,
        endDatetime: {
          value:
            AUTOMATION_CONTENT_TYPES_WITH_END_DATETIME.includes(
              props.automationItem.__typename,
            ) && endDatetimeConvertedFromLocalToUTC
              ? endDatetimeConvertedFromLocalToUTC
              : null,
        },
        newUsersOnly: AUTOMATION_CONTENT_TYPES_WITH_NEW_USERS_ONLY.includes(
          props.automationItem.__typename,
        )
          ? { value: newUsersOnly }
          : null,
      },
      countIsForReviewScreen: true,
    }),
    [
      endDatetimeConvertedFromLocalToUTC,
      newUsersOnly,
      props.automationItem.__typename,
      props.automationItem.automation.id,
      props.userSelectionInput,
      startDatetimeConvertedFromLocalToUTC,
    ],
  );
  const clearUserSelectionValidationError = useCallback(() => {
    setUserSelectionValidationError(undefined);
  }, []);
  const [diffAutomationChange] = useDiffAutomationChangeMutation({
    variables: {
      input,
    },
    onCompleted: (res) => {
      if (res.diffAutomationRulesChange.success) {
        setNumExistingUsers(
          res.diffAutomationRulesChange.diff?.added.length || 0,
        );
        setNumAddedImmediately(
          newUsersOnly ? 0 : res.diffAutomationRulesChange.diff?.added.length,
        );
        setNumScheduledToAdd(
          res.diffAutomationRulesChange.diff?.added.length
            ? 0
            : numExistingUsersWhoWillBeAssignedContentAfterAutomationStartDate,
        );
      } else {
        addErrorToast({
          ...res,
          callsite: "assign_automation_to_existing_users_count",
        });
      }
    },
  });
  const [updateAssignment, { loading: updateAssignmentLoading }] =
    useUpdateAutomationMutation({
      refetchQueries: props.refetchQueries,
      variables: {
        id: input.automationId,
        input: input.automationInput,
      },
      onCompleted: () => {
        props.createAutomationCompleted();
        launchConfetti();
        if (props.hideSuccessToast) return;
        addToast({
          message:
            "Automation updated." +
            (willSendNotifications &&
            numAddedImmediately &&
            numAddedImmediately > 0
              ? " All new assignees have been notified."
              : ""),
        });
      },
    });
  const validateFields = useCallback(() => {
    const hasDateValidationError =
      (startDatetime && endDatetime && startDatetime > endDatetime) ||
      (!startDatetime && endDatetime && new Date() > endDatetime);

    if (hasDateValidationError || !hasSelection) {
      if (hasDateValidationError) {
        setDateValidationError(
          "The start date must be earlier than the end date",
        );
      }
    }

    updateAssignment();
  }, [startDatetime, endDatetime, hasSelection, updateAssignment]);
  const confirmationBannerCopy = useMemo(() => {
    let copy = "";
    if (numAddedImmediately && numAddedImmediately > 0) {
      copy += `${numAddedImmediately} ${pluralize(
        "user",
        numAddedImmediately,
      )} will be assigned.`;
    }
    if (numScheduledToAdd && numScheduledToAdd > 0) {
      copy += `${numScheduledToAdd} existing ${pluralize(
        "user",
        numScheduledToAdd,
      )}`;
    }
    if (copy === "") {
      copy = "No users will be immediately impacted by this change";
    }
    return copy;
  }, [numAddedImmediately, numScheduledToAdd]);
  const reviewAutomationDetails = useMemo(() => {
    return (
      <AutoLayout
        direction="vertical"
        flex={1}
        alignSelf="stretch"
        spaceBetweenItems={24}
      >
        {showNewUsersOnlyToggle && hasSelection && (
          <>
            <HorizontalDivider color={deprecatedTones.gray5Alpha} />
            <AutoLayout
              direction="horizontal"
              alignSelf="stretch"
              spacingMode="space-between"
            >
              <AutoLayout direction="vertical" spaceBetweenItems={4}>
                <Text
                  type="P2"
                  fontWeight="SemiBold"
                  color={deprecatedTones.gray12}
                >
                  Assign to existing users
                </Text>
                <Text
                  type="P2"
                  fontWeight="Regular"
                  color={deprecatedTones.gray7}
                  loading={typeof numExistingUsers !== "number"}
                >
                  {numExistingUsers ? numExistingUsers : "No"}{" "}
                  {pluralize("employee", numExistingUsers || 0)} currently match
                  the above rules
                </Text>
              </AutoLayout>
              <ToggleButton
                manualKey="assign-to-existing-users"
                selected={newUsersOnly === false}
                onClick={() => setNewUsersOnly(!newUsersOnly)}
                size="24px"
              />
            </AutoLayout>
            <AutoLayout
              flex={1}
              alignSelf="stretch"
              alignmentVertical="center"
              padding={12}
              spaceBetweenItems={8}
              borderRadius={10}
              className={css(styles.assignmentReviewBanner)}
            >
              <DeprecatedIcon type="check" color={deprecatedTones.blue9} />
              <Text
                type="P2"
                fontWeight="Medium"
                multiline
                color={deprecatedTones.blue12}
              >
                {confirmationBannerCopy}
              </Text>
            </AutoLayout>
            <HorizontalDivider color={deprecatedTones.gray5Alpha} />
          </>
        )}
        {!!props.orgConfig?.creditCost && (
          <CreditsChargeWhenStartedBanner
            creditCostPerAssignment={props.orgConfig.creditCost}
          />
        )}
        {props.warnOnNoLocationsSelectedText &&
          props.userSelectionInput.locationIds?.length === 0 &&
          props.userSelectionInput.locationGroupIds?.length === 0 && (
            <AutoLayout
              flex={1}
              alignSelf="stretch"
              alignmentVertical="center"
              padding={12}
              spaceBetweenItems={8}
              borderRadius={10}
              styleDeclaration={styles.noLocationWarningBanner}
            >
              <DeprecatedIcon type="pin" />
              <Text multiline type="P2" color={deprecatedTones.yellow12}>
                {props.warnOnNoLocationsSelectedText}
              </Text>
            </AutoLayout>
          )}
        {showStartDateSelector || showEndDateSelector ? (
          <AutoLayout flex={1} alignSelf="stretch" direction="vertical">
            {showStartDateSelector ? (
              <AutoLayout flex={1} alignSelf="stretch" direction="vertical">
                <Text
                  type="P2"
                  fontWeight="SemiBold"
                  color={deprecatedTones.gray12}
                >
                  Start assigning
                </Text>
                <Spacer size={4} />
                <NowOrScheduledPicker
                  scheduledDateTime={startDatetime}
                  onScheduledDateTimeChange={(val: Date | null | undefined) => {
                    setStartDatetime(val);
                    setDateValidationError(undefined);
                  }}
                  fullWidth
                />
                <Spacer size={18} />
              </AutoLayout>
            ) : null}
            {showEndDateSelector ? (
              <AutoLayout flex={1} direction="vertical" alignSelf="stretch">
                <Text
                  type="P2"
                  fontWeight="SemiBold"
                  color={deprecatedTones.gray12}
                >
                  Stop assigning
                </Text>
                <Spacer size={4} />
                <NowOrScheduledPicker
                  scheduledDateTime={endDatetime}
                  onScheduledDateTimeChange={(val: Date | null | undefined) => {
                    setEndDatetime(val);
                    setDateValidationError(undefined);
                  }}
                  noDateSelectedLabel="Never"
                  fullWidth
                />
              </AutoLayout>
            ) : null}
            {dateValidationError && (
              <Text
                type="P3"
                fontWeight="Medium"
                color={deprecatedTones.red9}
                multiline
                style={{ marginTop: 8 }}
              >
                {dateValidationError}
              </Text>
            )}
          </AutoLayout>
        ) : null}
      </AutoLayout>
    );
  }, [
    confirmationBannerCopy,
    dateValidationError,
    endDatetime,
    hasSelection,
    newUsersOnly,
    numExistingUsers,
    props.orgConfig?.creditCost,
    showEndDateSelector,
    showNewUsersOnlyToggle,
    showStartDateSelector,
    startDatetime,
    props.warnOnNoLocationsSelectedText,
    props.userSelectionInput,
  ]);
  useEffect(() => {
    diffAutomationChange();
  }, [diffAutomationChange, input]);

  return {
    createAutomation: validateFields,
    createAutomationLoading: updateAssignmentLoading,
    userSelectionValidationError,
    clearUserSelectionValidationError,
    reviewAutomationDetails,
  };
};

const styles = StyleSheet.create({
  creditConfirmationBanner: {
    backgroundColor: deprecatedTones.green1Alpha,
    border: `1px solid ${deprecatedTones.green5Alpha}`,
  },
  assignmentReviewBanner: {
    backgroundColor: deprecatedTones.blue2,
    border: `1px solid ${deprecatedTones.blue3}`,
  },
  noLocationWarningBanner: {
    backgroundColor: deprecatedTones.yellow2,
    border: `1px solid ${deprecatedTones.yellow5}`,
  },
});

export default useReviewAutomationState;
