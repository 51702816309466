import { BuilderActionPartial } from "@contexts/BuilderActionContext";

const shouldActionsBeDeduped = (
  action1: BuilderActionPartial,
  action2: BuilderActionPartial,
): boolean => {
  const pba1 = action1.payload.pathBuilderAction;
  const pba2 = action2.payload.pathBuilderAction;
  const cba1 = action1.payload.courseBuilderAction;
  const cba2 = action2.payload.courseBuilderAction;
  const sba1 = action1.payload.skillBuilderAction;
  const sba2 = action2.payload.skillBuilderAction;
  const trba1 = action1.payload.trainingResourceBuilderAction;
  const trba2 = action2.payload.trainingResourceBuilderAction;
  return (
    actionsAreForSameContent(action1, action2) &&
    !!(
      (pba1?.setPathNameAction && pba2?.setPathNameAction) ||
      (pba1?.setPathDescriptionAction && pba2?.setPathDescriptionAction) ||
      (cba1?.setCourseNameAction && cba2?.setCourseNameAction) ||
      (cba1?.setCourseDescriptionAction && cba2?.setCourseDescriptionAction) ||
      (pba1?.setSectionBreakTitleAction &&
        pba2?.setSectionBreakTitleAction &&
        pba1.setSectionBreakTitleAction.sectionBreakUuid ===
          pba2.setSectionBreakTitleAction.sectionBreakUuid) ||
      (cba1?.setLessonNameAction &&
        cba2?.setLessonNameAction &&
        cba1.setLessonNameAction.lessonUuid ===
          cba2.setLessonNameAction.lessonUuid) ||
      (cba1?.setPromptTextAction &&
        cba2?.setPromptTextAction &&
        cba1.setPromptTextAction.promptUuid ===
          cba2.setPromptTextAction.promptUuid) ||
      (cba1?.setResponseOptionAnswerAction &&
        cba2?.setResponseOptionAnswerAction &&
        cba1.setResponseOptionAnswerAction.responseOptionId ===
          cba2.setResponseOptionAnswerAction.responseOptionId) ||
      (cba1?.setGradedFreeResponseCorrectAnswerAction &&
        cba2?.setGradedFreeResponseCorrectAnswerAction &&
        cba1.setGradedFreeResponseCorrectAnswerAction.promptUuid ===
          cba2.setGradedFreeResponseCorrectAnswerAction.promptUuid) ||
      (sba1?.setSkillNameAction && sba2?.setSkillNameAction) ||
      (sba1?.setSkillDescriptionAction && sba2?.setSkillDescriptionAction) ||
      (sba1?.setStepTextAction &&
        sba2?.setStepTextAction &&
        sba1.setStepTextAction.id === sba2.setStepTextAction.id) ||
      (trba1?.setRichTextDocumentContentAction &&
        trba2?.setRichTextDocumentContentAction &&
        trba1.trainingResourceId === trba2.trainingResourceId &&
        trba1.setRichTextDocumentContentAction.richTextDocumentId ===
          trba2.setRichTextDocumentContentAction.richTextDocumentId)
    )
  );
};

export default shouldActionsBeDeduped;

const actionsAreForSameContent = (
  action1: BuilderActionPartial,
  action2: BuilderActionPartial,
): boolean => {
  const pba1 = action1.payload.pathBuilderAction;
  const pba2 = action2.payload.pathBuilderAction;
  const cba1 = action1.payload.courseBuilderAction;
  const cba2 = action2.payload.courseBuilderAction;
  const sba1 = action1.payload.skillBuilderAction;
  const sba2 = action2.payload.skillBuilderAction;
  const trba1 = action1.payload.trainingResourceBuilderAction;
  const trba2 = action2.payload.trainingResourceBuilderAction;
  return (
    cba1?.courseId === cba2?.courseId &&
    pba1?.pathId === pba2?.pathId &&
    sba1?.skillId === sba2?.skillId &&
    trba1?.trainingResourceId === trba2?.trainingResourceId
  );
};
