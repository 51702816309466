import { BuilderActionPartial } from "@contexts/BuilderActionContext";
import { cloneDeep } from "lodash";
import { isRichTextEmpty } from "@src/components/libraryItemDetailPages/course/utils/utils";
import { SkillBuilder_SkillVersionFragment } from "../SkillDetailPage.generated";
import { VerificationStepType } from "@src/types.generated";

export type SkillValidationErrors = {
  hasNoName: boolean;
  stepsWithEmptyText: Set<string>;
  noVerificationSteps: boolean;
  sectionsWithNoSteps: Set<string>;
  sectionsWithErrors: Set<string>;
};

export const emptySkillValidationErrors = (): SkillValidationErrors => {
  return {
    hasNoName: false,
    stepsWithEmptyText: new Set(),
    noVerificationSteps: false,
    sectionsWithNoSteps: new Set(),
    sectionsWithErrors: new Set(),
  };
};

export const applyBuilderActionToSkillValidationErrors = (args: {
  validationErrors: SkillValidationErrors;
  action: BuilderActionPartial;
}): SkillValidationErrors => {
  const sba = args.action.payload.skillBuilderAction;
  if (!sba) return args.validationErrors;
  const validationErrors = cloneDeep(args.validationErrors);

  if (sba.setStepTextAction) {
    validationErrors.stepsWithEmptyText.delete(sba.setStepTextAction.id);
    validationErrors.sectionsWithErrors.clear();
    validationErrors.sectionsWithNoSteps.clear();
  } else if (sba.setSkillNameAction) {
    validationErrors.hasNoName = false;
  } else if (sba.addStepAction) {
    validationErrors.noVerificationSteps = false;
    if (sba.addStepAction.step.type !== VerificationStepType.SectionBreak) {
      validationErrors.sectionsWithNoSteps.clear();
      validationErrors.sectionsWithErrors.clear();
    }
  } else if (sba.removeStepAction) {
    validationErrors.stepsWithEmptyText.delete(sba.removeStepAction.id);
    validationErrors.sectionsWithNoSteps.delete(sba.removeStepAction.id);
    validationErrors.sectionsWithErrors.delete(sba.removeStepAction.id);
    validationErrors.sectionsWithErrors.clear();
    validationErrors.sectionsWithNoSteps.clear();
  }
  return validationErrors;
};

export const validateSkillVersion = (
  sv: SkillBuilder_SkillVersionFragment,
): SkillValidationErrors => {
  const ret = emptySkillValidationErrors();
  if (sv.verificationSteps.length === 0) {
    ret.noVerificationSteps = true;
  } else {
    const steps = sv.verificationSteps;
    const filteredSteps = steps.filter(
      (s) => s.type !== VerificationStepType.PassFail,
    );

    filteredSteps.forEach((s) => {
      if (isRichTextEmpty(s.text?.en || "")) {
        ret.stepsWithEmptyText.add(s.id);
      }
    });

    let currentSectionId: string | null = null;
    let hasStepInCurrentSection = false;
    let currentSectionHasError = false;

    filteredSteps.forEach((step) => {
      if (step.type === VerificationStepType.SectionBreak) {
        if (currentSectionId) {
          if (!hasStepInCurrentSection) {
            ret.sectionsWithNoSteps.add(currentSectionId);
            ret.sectionsWithErrors.add(currentSectionId);
          } else if (currentSectionHasError) {
            ret.sectionsWithErrors.add(currentSectionId);
          }
        }
        currentSectionId = step.id;
        hasStepInCurrentSection = false;
        currentSectionHasError = false;
      } else if (currentSectionId) {
        hasStepInCurrentSection = true;
        if (ret.stepsWithEmptyText.has(step.id)) {
          currentSectionHasError = true;
        }
      }
    });

    if (currentSectionId) {
      if (!hasStepInCurrentSection) {
        ret.sectionsWithNoSteps.add(currentSectionId);
        ret.sectionsWithErrors.add(currentSectionId);
      } else if (currentSectionHasError) {
        ret.sectionsWithErrors.add(currentSectionId);
      }
    }
  }

  if (isRichTextEmpty(sv.skill.libraryItem.name?.en || "")) {
    ret.hasNoName = true;
  }

  return ret;
};

export const skillValidationErrorCount = (
  errors: SkillValidationErrors,
): number => {
  return (
    errors.stepsWithEmptyText.size +
    errors.sectionsWithNoSteps.size +
    (errors.hasNoName ? 1 : 0) +
    (errors.noVerificationSteps ? 1 : 0)
  );
};

export const hasSkillValidationErrors = (
  errors: SkillValidationErrors,
): boolean => {
  return skillValidationErrorCount(errors) > 0;
};
