import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectUsersModal_UsersQueryVariables = Types.Exact<{
  input: Types.GetPeopleInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type SelectUsersModal_UsersQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number, objects: Array<{ __typename?: 'Employee', id: number, name: string, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> }> } };

export type SelectUsersModal_UserThinFragment = { __typename?: 'Employee', id: number, name: string, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> };

export type SelectUsersModal_LocationGroupsQueryVariables = Types.Exact<{
  type: Types.LocationGroupType;
}>;


export type SelectUsersModal_LocationGroupsQuery = { __typename?: 'Query', LocationGroups: { __typename?: 'LocationGroups', objects: Array<{ __typename?: 'LocationGroup', id: string, name: string }> } };

export type SelectUsersModal_LocationGroupFragment = { __typename?: 'LocationGroup', id: string, name: string };

export const SelectUsersModal_UserThinFragmentDoc = gql`
    fragment SelectUsersModal_UserThin on Employee {
  id
  name
  locations {
    id
    name
  }
  roles {
    id
    name
  }
}
    `;
export const SelectUsersModal_LocationGroupFragmentDoc = gql`
    fragment SelectUsersModal_LocationGroup on LocationGroup {
  id
  name
}
    `;
export const SelectUsersModal_UsersDocument = gql`
    query SelectUsersModal_Users($input: GetPeopleInput!, $pagination: PaginationInput) {
  People(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...SelectUsersModal_UserThin
    }
  }
}
    ${SelectUsersModal_UserThinFragmentDoc}`;

/**
 * __useSelectUsersModal_UsersQuery__
 *
 * To run a query within a React component, call `useSelectUsersModal_UsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectUsersModal_UsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectUsersModal_UsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSelectUsersModal_UsersQuery(baseOptions: Apollo.QueryHookOptions<SelectUsersModal_UsersQuery, SelectUsersModal_UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectUsersModal_UsersQuery, SelectUsersModal_UsersQueryVariables>(SelectUsersModal_UsersDocument, options);
      }
export function useSelectUsersModal_UsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectUsersModal_UsersQuery, SelectUsersModal_UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectUsersModal_UsersQuery, SelectUsersModal_UsersQueryVariables>(SelectUsersModal_UsersDocument, options);
        }
export type SelectUsersModal_UsersQueryHookResult = ReturnType<typeof useSelectUsersModal_UsersQuery>;
export type SelectUsersModal_UsersLazyQueryHookResult = ReturnType<typeof useSelectUsersModal_UsersLazyQuery>;
export type SelectUsersModal_UsersQueryResult = Apollo.QueryResult<SelectUsersModal_UsersQuery, SelectUsersModal_UsersQueryVariables>;
export const SelectUsersModal_LocationGroupsDocument = gql`
    query SelectUsersModal_LocationGroups($type: LocationGroupType!) {
  LocationGroups(input: {types: [$type]}) {
    objects {
      ...SelectUsersModal_LocationGroup
    }
  }
}
    ${SelectUsersModal_LocationGroupFragmentDoc}`;

/**
 * __useSelectUsersModal_LocationGroupsQuery__
 *
 * To run a query within a React component, call `useSelectUsersModal_LocationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectUsersModal_LocationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectUsersModal_LocationGroupsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSelectUsersModal_LocationGroupsQuery(baseOptions: Apollo.QueryHookOptions<SelectUsersModal_LocationGroupsQuery, SelectUsersModal_LocationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectUsersModal_LocationGroupsQuery, SelectUsersModal_LocationGroupsQueryVariables>(SelectUsersModal_LocationGroupsDocument, options);
      }
export function useSelectUsersModal_LocationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectUsersModal_LocationGroupsQuery, SelectUsersModal_LocationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectUsersModal_LocationGroupsQuery, SelectUsersModal_LocationGroupsQueryVariables>(SelectUsersModal_LocationGroupsDocument, options);
        }
export type SelectUsersModal_LocationGroupsQueryHookResult = ReturnType<typeof useSelectUsersModal_LocationGroupsQuery>;
export type SelectUsersModal_LocationGroupsLazyQueryHookResult = ReturnType<typeof useSelectUsersModal_LocationGroupsLazyQuery>;
export type SelectUsersModal_LocationGroupsQueryResult = Apollo.QueryResult<SelectUsersModal_LocationGroupsQuery, SelectUsersModal_LocationGroupsQueryVariables>;