import { GraphqlOperations, LocationGroupType } from "@src/types.generated";
import { UserSelectionFilterType } from "./SelectUsersModal";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";

export const ASSIGNMENT_REFETCH_QUERIES = [
  GraphqlOperations.Query.LibraryItemDetailPage_LibraryItem,
  GraphqlOperations.Query.ModuleManageAssignmentsTab_Assignments,
  GraphqlOperations.Query.ModuleManageLibraryAccessTab_Assignments,
  GraphqlOperations.Query.PremiumPathDetailAssignmentsTable_Assignments,
  GraphqlOperations.Query.PremiumPathDetailCertificatesCount,
  GraphqlOperations.Query.PremiumPathDetailHeader,
  GraphqlOperations.Query.CourseManageTabAssignments,
  GraphqlOperations.Query.CourseDetailHeader,
  GraphqlOperations.Query.AssignmentStatusCounts,
  GraphqlOperations.Query.ComplianceRecords,
  GraphqlOperations.Query.ComplianceSummary,
  GraphqlOperations.Query.CreditBalance,
  GraphqlOperations.Query.UserDetailAssignments,
  GraphqlOperations.Query.UserDetailCompletedTrainingTabAssignments,
  GraphqlOperations.Query.UserPageContentTabCounts,
  GraphqlOperations.Query.OrgPremiumContentConfigs,
  GraphqlOperations.Query.ModuleManageSettingsTab_Path,
  GraphqlOperations.Query.PremiumPathDetailHeader,
  GraphqlOperations.Query.ModuleManageAssignmentsTab_Assignments,
  GraphqlOperations.Query.PremiumPathDetailOverview,
  GraphqlOperations.Query.SharpDetailAssignmentsTable_Assignments,
];

export const USER_SELECTION_ICON: {
  [key in UserSelectionFilterType]: DeprecatedIconType | "avatar";
} = {
  locations: "pin",
  roles: "id-badge",
  departments: "users-circle",
  people: "avatar",
  [LocationGroupType.Brand]: "registered-trademark",
  [LocationGroupType.Region]: "globe-with-pin",
  [LocationGroupType.Franchise]: "git-branch",
  hireDate: "calendar",
};
