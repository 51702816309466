import { FC, useCallback, useMemo } from "react";
import {
  DeprecateTone,
  DeprecatedToneName,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import InlineWrapCard from "@src/deprecatedDesignSystem/components/InlineWrapCard";
import RoundedPill from "@src/deprecatedDesignSystem/components/RoundedPill";
import Text from "@ui/text";
import useUserSelections from "./hooks/useUserSelections";
import { LocationGroupType, UserSelectionInput } from "@src/types.generated";
import IndentedLeftBorder from "@src/deprecatedDesignSystem/components/IndentedLeftBorder";
import EmptyState from "@src/deprecatedDesignSystem/components/EmptyState";
import useHiddenLocationGroupTypes from "@src/components/home/hooks/useHiddenLocationGroupTypes";
import {
  USER_SELECTION_FILTERS,
  UserSelectionFilterType,
} from "./SelectUsersModal";
import { cloneDeep, toLower } from "lodash";
import { USER_SELECTION_ICON } from "./constants";
import { formatSentenceList } from "./utils";
import useUserSelectionPreviewState from "./hooks/useUserSelectionPreviewState";

type Props = {
  input: UserSelectionInput;
  setInput?: (input: UserSelectionInput) => void;
  hideFilters?: UserSelectionFilterType[];
  showUserTypeOptions?: boolean;
  pillColor?: DeprecatedToneName;
  containerPadding?: number;
  containerBackgroundColor?: DeprecateTone | "initial";
  showEmptyState?: boolean;
  useLocationSelectionCopy?: boolean;
  showCards?: boolean;
};

const UserSelectionPreview: FC<Props> = ({
  pillColor = "gray",
  showUserTypeOptions = false,
  containerPadding = 12,
  containerBackgroundColor = deprecatedTones.gray1,
  showEmptyState = true,
  useLocationSelectionCopy = false,
  showCards = true,
  ...props
}) => {
  const { hasSelection, nestLocationAndLocationGroupSelections } =
    useUserSelectionPreviewState({
      input: props.input,
      showUserTypeOptions: showUserTypeOptions,
      useLocationSelectionCopy: useLocationSelectionCopy,
    });
  const hiddenFilters = useHiddenLocationGroupTypes();
  const visibleFilters = useMemo(
    () =>
      USER_SELECTION_FILTERS.filter(
        (filter) => !hiddenFilters.includes(filter as LocationGroupType),
      )
        .filter((x) => !props.hideFilters?.includes(x))
        .map((x) => {
          if (x === "people") {
            return "name";
          } else if (x === "hireDate") {
            return "hire date";
          } else {
            return x;
          }
        })
        .map((x) => toLower(x as string)),
    [hiddenFilters, props.hideFilters],
  );
  const subtitle = useMemo(() => {
    return `Select ${useLocationSelectionCopy ? "locations" : "users"} by ${formatSentenceList(visibleFilters, "or")}`;
  }, [visibleFilters, useLocationSelectionCopy]);
  return (
    <AutoLayout
      flex={1}
      spaceBetweenItems={12}
      padding={containerPadding}
      direction="vertical"
      alignSelf="stretch"
      alignmentHorizontal="center"
      backgroundColor={containerBackgroundColor}
    >
      {hasSelection && (
        <>
          <AutoLayout
            direction="vertical"
            spaceBetweenItems={showCards ? 8 : 0}
            alignSelf="stretch"
          >
            <UserTypesRoleGroupAndRolesCard
              input={props.input}
              pillColor={pillColor}
              setInput={props.setInput}
              showUserTypeOptions={showUserTypeOptions}
              showCards={showCards}
            />
            <IndentedLeftBorder
              showIndent={nestLocationAndLocationGroupSelections}
            >
              <LocationGroupsCard
                input={props.input}
                pillColor={pillColor}
                setInput={props.setInput}
                showUserTypeOptions={showUserTypeOptions}
                useLocationSelectionCopy={useLocationSelectionCopy}
                showCards={showCards}
              />
              <LocationsCard
                input={props.input}
                pillColor={pillColor}
                setInput={props.setInput}
                showUserTypeOptions={showUserTypeOptions}
                useLocationSelectionCopy={useLocationSelectionCopy}
                showCards={showCards}
              />
            </IndentedLeftBorder>
            {props.input.daysAfterHire && !isNaN(props.input.daysAfterHire) && (
              <IndentedLeftBorder showIndent>
                <DaysAfterHireCard input={props.input} pillColor={pillColor} />
              </IndentedLeftBorder>
            )}
          </AutoLayout>
          <UsersCard
            input={props.input}
            pillColor={pillColor}
            setInput={props.setInput}
            showUserTypeOptions={showUserTypeOptions}
            showCards={showCards}
          />
        </>
      )}
      {!hasSelection && showEmptyState && (
        <EmptyState
          icon="plus-circle"
          iconSize={32}
          title={`Select ${useLocationSelectionCopy ? "locations" : "users"}`}
          subtitle={subtitle}
        />
      )}
    </AutoLayout>
  );
};

const UserTypesRoleGroupAndRolesCard: FC<Props> = (props) => {
  const { selectedRoles, selectedRoleGroups } = useUserSelections(props.input);
  const {
    showRoles,
    showRoleGroups,
    userTypesLabel,
    rolesLabel,
    roleGroupsLabel,
  } = useUserSelectionPreviewState({
    input: props.input,
    showUserTypeOptions: props.showUserTypeOptions,
    useLocationSelectionCopy: props.useLocationSelectionCopy,
  });
  const noUserTypesSelected = useMemo(() => {
    return (
      !props.input.allTrainees &&
      !props.input.allManagers &&
      !props.input.allAdmins
    );
  }, [props.input.allAdmins, props.input.allManagers, props.input.allTrainees]);
  const locationsOrLocationGroupsSelected = useMemo(() => {
    return (
      !!props.input.locationIds?.length ||
      !!props.input.locationGroupIds?.length
    );
  }, [props.input.locationGroupIds?.length, props.input.locationIds?.length]);
  if (!showRoles && !showRoleGroups) {
    return null;
  }

  return (
    <InlineWrapCard showCard={props.showCards}>
      {userTypesLabel && (
        <Text type="P2" fontWeight="Medium">
          {userTypesLabel}
        </Text>
      )}
      {props.showUserTypeOptions && (
        <AutoLayout spaceBetweenItems={4}>
          {props.input.allTrainees && (
            <RoundedPill
              iconOrAvatar={USER_SELECTION_ICON["roles"]}
              label="Trainees"
              color={props.pillColor}
              onClick={
                props.setInput
                  ? () => {
                      const newInput = cloneDeep(props.input);
                      newInput.allTrainees = false;
                      props.setInput?.(newInput);
                    }
                  : undefined
              }
            />
          )}
          {props.input.allManagers && (
            <RoundedPill
              iconOrAvatar={USER_SELECTION_ICON["roles"]}
              label="Managers"
              color={props.pillColor}
              onClick={
                props.setInput
                  ? () => {
                      const newInput = cloneDeep(props.input);
                      newInput.allManagers = false;
                      props.setInput?.(newInput);
                    }
                  : undefined
              }
            />
          )}
          {props.input.allAdmins && (
            <RoundedPill
              iconOrAvatar={USER_SELECTION_ICON["roles"]}
              label="Admins"
              color={props.pillColor}
              onClick={
                props.setInput
                  ? () => {
                      const newInput = cloneDeep(props.input);
                      newInput.allAdmins = false;
                      props.setInput?.(newInput);
                    }
                  : undefined
              }
            />
          )}
        </AutoLayout>
      )}
      {roleGroupsLabel && (
        <Text type="P2" fontWeight="Medium">
          {roleGroupsLabel}
        </Text>
      )}
      {selectedRoleGroups.map((roleGroup) => (
        <RoundedPill
          key={roleGroup.id}
          iconOrAvatar={USER_SELECTION_ICON["departments"]}
          label={roleGroup.name}
          color={props.pillColor}
          onClick={
            props.setInput
              ? () => {
                  const newInput = cloneDeep(props.input);
                  newInput.roleGroupIds = newInput.roleGroupIds?.filter(
                    (x) => x !== roleGroup.id,
                  );
                  if (
                    newInput.roleIds?.length === 0 &&
                    newInput.roleGroupIds?.length === 0 &&
                    noUserTypesSelected &&
                    locationsOrLocationGroupsSelected
                  ) {
                    newInput.allTrainees = true;
                    newInput.allManagers = true;
                    newInput.allAdmins = true;
                  }
                  props.setInput?.(newInput);
                }
              : undefined
          }
        />
      ))}
      {rolesLabel && (
        <Text type="P2" fontWeight="Medium">
          {rolesLabel}
        </Text>
      )}
      {selectedRoles.map((role) => (
        <RoundedPill
          key={role.id}
          iconOrAvatar={USER_SELECTION_ICON["roles"]}
          label={role.name}
          color={props.pillColor}
          onClick={
            props.setInput
              ? () => {
                  const newInput = cloneDeep(props.input);
                  newInput.roleIds = newInput.roleIds?.filter(
                    (x) => x !== role.id,
                  );
                  if (
                    newInput.roleIds?.length === 0 &&
                    newInput.roleGroupIds?.length === 0 &&
                    noUserTypesSelected &&
                    locationsOrLocationGroupsSelected
                  ) {
                    newInput.allTrainees = true;
                    newInput.allManagers = true;
                    newInput.allAdmins = true;
                  }
                  props.setInput?.(newInput);
                }
              : undefined
          }
        />
      ))}
    </InlineWrapCard>
  );
};

const LocationGroupsCard: FC<Props> = (props) => {
  const { showLocationGroups, locationGroupsLabel, showRoles } =
    useUserSelectionPreviewState({
      input: props.input,
      showUserTypeOptions: props.showUserTypeOptions,
      useLocationSelectionCopy: props.useLocationSelectionCopy,
    });
  const { selectedLocationGroups, selectedLocationGroupsCount } =
    useUserSelections(props.input);
  const isLastLocationFilter = useMemo(() => {
    return (
      (props.input.locationIds?.length || 0) +
        (props.input.locationGroupIds?.length || 0) ===
      1
    );
  }, [props.input.locationGroupIds?.length, props.input.locationIds?.length]);
  const allUserTypesSelected = useMemo(() => {
    return (
      props.input.allTrainees &&
      props.input.allManagers &&
      props.input.allAdmins
    );
  }, [props.input.allAdmins, props.input.allManagers, props.input.allTrainees]);
  const handleClick = useCallback(
    (id: string) => {
      if (props.setInput) {
        const newInput = cloneDeep(props.input);
        newInput.locationGroupIds = newInput.locationGroupIds?.filter(
          (x) => x !== id,
        );
        if (isLastLocationFilter && allUserTypesSelected) {
          newInput.allTrainees = false;
          newInput.allManagers = false;
          newInput.allAdmins = false;
          newInput.roleIds = [];
        }
        props.setInput(newInput);
      }
    },
    [allUserTypesSelected, isLastLocationFilter, props],
  );
  const getOnClickX = useCallback(
    (lgId: string) => {
      return props.setInput
        ? () => {
            handleClick(lgId);
          }
        : undefined;
    },
    [handleClick, props.setInput],
  );
  if (!showLocationGroups) {
    return null;
  }

  return (
    <InlineWrapCard showCard={props.showCards}>
      {!showRoles && (
        <Text type="P2" fontWeight="Medium">
          All {props.useLocationSelectionCopy ? "locations" : "users"}
        </Text>
      )}
      {selectedLocationGroupsCount.FRANCHISE > 0 && (
        <>
          <Text type="P2" fontWeight="Medium">
            {locationGroupsLabel?.FRANCHISE}
          </Text>
          {selectedLocationGroups.FRANCHISE.map((franchise) => (
            <RoundedPill
              key={franchise.id}
              iconOrAvatar={USER_SELECTION_ICON[LocationGroupType.Franchise]}
              label={franchise.name}
              color={props.pillColor}
              onClick={getOnClickX(franchise.id)}
            />
          ))}
        </>
      )}
      {selectedLocationGroupsCount.BRAND > 0 && (
        <>
          <Text type="P2" fontWeight="Medium">
            {locationGroupsLabel?.BRAND}
          </Text>
          {selectedLocationGroups.BRAND.map((brand) => (
            <RoundedPill
              key={brand.id}
              iconOrAvatar={USER_SELECTION_ICON[LocationGroupType.Brand]}
              label={brand.name}
              color={props.pillColor}
              onClick={getOnClickX(brand.id)}
            />
          ))}
        </>
      )}
      {selectedLocationGroupsCount.REGION > 0 && (
        <>
          <Text type="P2" fontWeight="Medium">
            {locationGroupsLabel?.REGION}
          </Text>
          {selectedLocationGroups.REGION.map((region) => (
            <RoundedPill
              key={region.id}
              iconOrAvatar={USER_SELECTION_ICON[LocationGroupType.Region]}
              label={region.name}
              color={props.pillColor}
              onClick={getOnClickX(region.id)}
            />
          ))}
        </>
      )}
    </InlineWrapCard>
  );
};

const LocationsCard: FC<Props> = (props) => {
  const { showLocations, locationsLabel } = useUserSelectionPreviewState({
    input: props.input,
    showUserTypeOptions: props.showUserTypeOptions,
    useLocationSelectionCopy: props.useLocationSelectionCopy,
  });
  const { selectedLocations } = useUserSelections(props.input);
  const isLastLocationFilter = useMemo(() => {
    return (
      (props.input.locationIds?.length || 0) +
        (props.input.locationGroupIds?.length || 0) ===
      1
    );
  }, [props.input.locationGroupIds?.length, props.input.locationIds?.length]);
  const allUserTypesSelected = useMemo(() => {
    return (
      props.input.allTrainees &&
      props.input.allManagers &&
      props.input.allAdmins
    );
  }, [props.input.allAdmins, props.input.allManagers, props.input.allTrainees]);
  if (!showLocations) {
    return null;
  }

  return (
    <InlineWrapCard showCard={props.showCards}>
      <Text type="P2" fontWeight="Medium">
        {locationsLabel}
      </Text>
      {selectedLocations.map((location) => (
        <RoundedPill
          key={location.id}
          iconOrAvatar={USER_SELECTION_ICON["locations"]}
          label={location.name}
          color={props.pillColor}
          onClick={
            props.setInput
              ? () => {
                  const newInput = cloneDeep(props.input);
                  newInput.locationIds = newInput.locationIds?.filter(
                    (x) => x !== location.id,
                  );
                  if (isLastLocationFilter && allUserTypesSelected) {
                    newInput.allTrainees = false;
                    newInput.allManagers = false;
                    newInput.allAdmins = false;
                    newInput.roleIds = [];
                  }
                  props.setInput?.(newInput);
                }
              : undefined
          }
        />
      ))}
    </InlineWrapCard>
  );
};

const DaysAfterHireCard: FC<Props> = (props) => {
  if (!props.input.daysAfterHire) {
    return null;
  }

  return (
    <InlineWrapCard showCard={props.showCards}>
      <RoundedPill
        iconOrAvatar="calendar"
        label={`${props.input.daysAfterHire} ${
          props.input.daysAfterHire === 1 ? "day" : "days"
        } after hire`}
        color={props.pillColor}
      />
    </InlineWrapCard>
  );
};

const UsersCard: FC<Props> = (props) => {
  const { showUsers, usersLabel } = useUserSelectionPreviewState({
    input: props.input,
    showUserTypeOptions: props.showUserTypeOptions,
    useLocationSelectionCopy: props.useLocationSelectionCopy,
  });
  const { selectedUsers } = useUserSelections(props.input);
  if (!showUsers) {
    return null;
  }
  return (
    <InlineWrapCard showCard={props.showCards}>
      <Text type="P2" fontWeight="Medium">
        {usersLabel}
      </Text>
      {selectedUsers.map((user) => (
        <RoundedPill
          key={user.id}
          iconOrAvatar="avatar"
          label={user.name}
          color={props.pillColor}
          onClick={
            props.setInput
              ? () => {
                  const newInput = cloneDeep(props.input);
                  newInput.userIds = newInput.userIds?.filter(
                    (x) => x !== user.id,
                  );
                  props.setInput?.(newInput);
                }
              : undefined
          }
        />
      ))}
    </InlineWrapCard>
  );
};

export default UserSelectionPreview;
