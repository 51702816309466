import { FC, UIEvent, useCallback, useMemo, useState } from "react";
import { gql } from "@apollo/client";
import { cloneDeep, debounce } from "lodash";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import DeprecatedIcon, {
  DeprecatedIconType,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import { useModal } from "@src/hooks/useModal";
import { css, StyleSheet } from "aphrodite";
import { cn } from "@src/ui/lib/utils";
import {
  DeprecatedToneName,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import useHiddenLocationGroupTypes from "@src/components/home/hooks/useHiddenLocationGroupTypes";
import {
  LocationGroupType,
  UserSelectionInput,
  UserType,
} from "@src/types.generated";
import Pill from "@src/deprecatedDesignSystem/components/Pill";
import useLocationGroups from "@src/hooks/useLocationGroups";
import useLocations from "@src/hooks/useLocations";
import useRoles from "@src/hooks/useRoles";
import {
  curves,
  durations,
  properties,
} from "@src/deprecatedDesignSystem/styles/transitions";
import Fuse from "fuse.js";
import UserSelectionPreview from "./UserSelectionPreview";
import { isLocationGroupType, toCamelCase } from "./utils";
import Circle from "@src/deprecatedDesignSystem/components/Circle";
import {
  SelectUsersModal_UserThinFragment,
  useSelectUsersModal_UsersQuery,
} from "./SelectUsersModal.generated";
import { LocationGroupThinFragment } from "@src/hooks/useLocationGroups.generated";
import { LocationThinFragment } from "@src/hooks/useLocations.generated";
import { RoleThinFragment } from "@src/hooks/useRoles.generated";
import useUserSelections from "./hooks/useUserSelections";
import useGetUsersForOneOffAssignment from "./hooks/useGetUsersForOneOffAssignment";
import { pluralize } from "@src/utils/strings";
import Avatar from "@src/components/ui/Avatar";
import { USER_SELECTION_ICON } from "./constants";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";
import Spacer from "@src/components/ui/Spacer";
import useUserSelectionInputState from "./hooks/useUserSelectionInputState";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";
import useRoleGroups from "@src/hooks/useRoleGroups";
import { RoleGroupThinFragment } from "@src/hooks/useRoleGroups.generated";
import UpdateAccessMultiSelectConfirmationModal from "../UpdateAccessMultiSelectConfirmationModal";
import { Input } from "@src/ui/input";
import { Label } from "@src/ui/label";
import { Tooltip } from "@src/ui/tooltip";
import { TooltipTrigger } from "@src/ui/tooltip";
import { TooltipContent } from "@src/ui/tooltip";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

const PAGINATION_LIMIT = 30;
const FUSE_CONFIG = { keys: ["name"] };

export type UserSelectionFilterType =
  | "locations"
  | "roles"
  | "departments"
  | "people"
  | LocationGroupType
  | "hireDate";
export const USER_SELECTION_FILTERS: UserSelectionFilterType[] = [
  "roles",
  "departments",
  "locations",
  LocationGroupType.Region,
  LocationGroupType.Brand,
  LocationGroupType.Franchise,
  "hireDate",
  "people",
];

type Props = {
  onSelect: (input: UserSelectionInput) => void;
  input?: UserSelectionInput;
  showSelectedUsersCount?: boolean;
  title?: string;
  hideFilters?: UserSelectionFilterType[];
  showUserTypeOptions?: boolean;
  hideUserTypes?: UserType[];
  pillColor?: DeprecatedToneName;
  selectedItemsCount?: number;
  showConfirmationModal?: boolean;
  useLocationSelectionCopy?: boolean;
};

const SelectUsersModal: FC<Props> = ({
  pillColor = "blue",
  showSelectedUsersCount = true,
  title = "Select users",
  showUserTypeOptions = false,
  hideUserTypes,
  selectedItemsCount,
  showConfirmationModal = false,
  useLocationSelectionCopy = false,
  ...props
}) => {
  const { userSelectionInput, setUserSelectionInput } =
    useUserSelectionInputState({
      input: props.input,
      allowUserTypes: showUserTypeOptions,
    });
  const usersSelectedCount = useGetUsersForOneOffAssignment(userSelectionInput);
  const { showModal, closeModal } = useModal();

  const modalHeader = useMemo(() => {
    const element = (
      <Text type="P2" fontWeight="SemiBold">
        {title}
      </Text>
    );
    return <TitleHeader element={element} onCancelClick={closeModal} />;
  }, [closeModal, title]);
  const modalFooter = useMemo(() => {
    const primaryButton = (
      <Button
        text={
          useLocationSelectionCopy
            ? "Select locations"
            : showSelectedUsersCount
              ? `Select ${usersSelectedCount.length} ${pluralize(
                  "user",
                  usersSelectedCount.length,
                )}`
              : "Select"
        }
        height="40px"
        onClick={() => {
          if (showConfirmationModal) {
            showModal(
              <UpdateAccessMultiSelectConfirmationModal
                onConfirm={() => props.onSelect(userSelectionInput)}
                selectedItemsCount={selectedItemsCount || 1}
              />,
            );
          } else {
            props.onSelect(userSelectionInput);
            closeModal();
          }
        }}
        data-testid={"confirm-button"}
      />
    );
    return (
      <Footer
        primaryButton={primaryButton}
        cancelTitle="Cancel"
        onCancelClick={closeModal}
      />
    );
  }, [
    closeModal,
    props,
    showSelectedUsersCount,
    userSelectionInput,
    usersSelectedCount.length,
    selectedItemsCount,
    showModal,
    showConfirmationModal,
    useLocationSelectionCopy,
  ]);

  return (
    <Modal
      width={800}
      header={modalHeader}
      footer={modalFooter}
      dataTestId={"select-users-modal"}
    >
      <AutoLayout height={450}>
        <LeftSidePanel
          input={userSelectionInput}
          showUserTypeOptions={showUserTypeOptions}
          setInput={setUserSelectionInput}
          hideFilters={props.hideFilters}
          hideUserTypes={hideUserTypes}
        />
        <AutoLayout direction="vertical" flex={1} alignSelf="stretch">
          <UserSelectionPreview
            pillColor={pillColor}
            input={userSelectionInput}
            showUserTypeOptions={showUserTypeOptions}
            setInput={setUserSelectionInput}
            useLocationSelectionCopy={useLocationSelectionCopy}
          />
        </AutoLayout>
      </AutoLayout>
    </Modal>
  );
};

type LeftSidePanelProps = {
  input: UserSelectionInput;
  setInput: (input: UserSelectionInput) => void;
  showUserTypeOptions?: boolean;
  hideFilters?: UserSelectionFilterType[];
  hideUserTypes?: UserType[];
};
const LeftSidePanel: FC<LeftSidePanelProps> = (props) => {
  const hiddenFilters = useHiddenLocationGroupTypes();
  const visibleFilters = useMemo(
    () =>
      USER_SELECTION_FILTERS.filter(
        (filter) => !hiddenFilters.includes(filter as LocationGroupType),
      ).filter((filter) => !props.hideFilters?.includes(filter)),
    [hiddenFilters, props.hideFilters],
  );
  const allUserTypeFiltersAreSelected = useMemo(() => {
    return (
      props.input.allTrainees &&
      props.input.allManagers &&
      props.input.allAdmins
    );
  }, [props.input.allAdmins, props.input.allManagers, props.input.allTrainees]);
  const {
    selectedLocationGroups,
    selectedRoles,
    selectedRoleGroups,
    selectedLocations,
    selectedUsers,
  } = useUserSelections(props.input);
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const [selectedFilter, setSelectedFilter] = useState<UserSelectionFilterType>(
    visibleFilters[0],
  );
  const { locationGroups } = useLocationGroups();
  const { locations } = useLocations();
  const { roleGroups } = useRoleGroups();
  const { roles } = useRoles();
  const getPeopleQueryInput = useMemo(() => {
    return {
      search:
        debouncedSearch.length > 0 ? { value: debouncedSearch } : undefined,
      sort: { column: "name", descending: false },
    };
  }, [debouncedSearch]);
  const { data: usersData, fetchMore } = useSelectUsersModal_UsersQuery({
    variables: {
      input: getPeopleQueryInput,
      pagination: {
        limit: PAGINATION_LIMIT,
        offset: 0,
      },
    },
  });
  const totalUsers = useMemo(() => {
    return usersData?.People.totalCount || 0;
  }, [usersData?.People.totalCount]);
  const users: SelectUsersModal_UserThinFragment[] = useMemo(() => {
    return usersData?.People.objects || [];
  }, [usersData?.People.objects]);
  const hasMoreUsers = useMemo(() => {
    return users.length < totalUsers;
  }, [users, totalUsers]);
  const visibleUserSelectionFilterOptions = useMemo(() => {
    if (selectedFilter === LocationGroupType.Brand) {
      const selectedBrands = selectedLocationGroups[LocationGroupType.Brand];
      const visibleBrands = locationGroups
        .filter((x) => !selectedBrands.includes(x))
        .filter((x) => x.type === LocationGroupType.Brand);
      if (search) {
        const fuse = new Fuse(visibleBrands, FUSE_CONFIG);
        return fuse.search(search).map((r) => r.item);
      }
      return visibleBrands;
    } else if (selectedFilter === LocationGroupType.Franchise) {
      const selectedFranchises =
        selectedLocationGroups[LocationGroupType.Franchise];
      const visibleFranchises = locationGroups
        .filter((x) => !selectedFranchises.includes(x))
        .filter((x) => x.type === LocationGroupType.Franchise);
      if (search) {
        const fuse = new Fuse(visibleFranchises, FUSE_CONFIG);
        return fuse.search(search).map((r) => r.item);
      }
      return visibleFranchises;
    } else if (selectedFilter === LocationGroupType.Region) {
      const selectedRegions = selectedLocationGroups[LocationGroupType.Region];
      const visibleRegions = locationGroups
        .filter((x) => !selectedRegions.includes(x))
        .filter((x) => x.type === LocationGroupType.Region);
      if (search) {
        const fuse = new Fuse(visibleRegions, FUSE_CONFIG);
        return fuse.search(search).map((r) => r.item);
      }
      return visibleRegions;
    } else if (selectedFilter === "locations") {
      const visibleLocations = locations.filter(
        (x) => !selectedLocations.includes(x),
      );
      if (search) {
        const fuse = new Fuse(visibleLocations, FUSE_CONFIG);
        return fuse.search(search).map((r) => r.item);
      }
      return visibleLocations;
    } else if (selectedFilter === "roles") {
      const visibleRoles = roles.filter((x) => !selectedRoles.includes(x));
      if (search) {
        const fuse = new Fuse(visibleRoles, FUSE_CONFIG);
        return fuse.search(search).map((r) => r.item);
      }
      return visibleRoles;
    } else if (selectedFilter === "departments") {
      const visibleRoleGroups = roleGroups.filter(
        (x) => !selectedRoleGroups.includes(x),
      );
      if (search) {
        const fuse = new Fuse(visibleRoleGroups, FUSE_CONFIG);
        return fuse.search(search).map((r) => r.item);
      }
      return visibleRoleGroups;
    } else if (selectedFilter === "people") {
      return users.filter((x) => !selectedUsers.some((y) => y.id === x.id));
    } else {
      return [];
    }
  }, [
    selectedFilter,
    selectedLocationGroups,
    locationGroups,
    search,
    locations,
    selectedLocations,
    roles,
    selectedRoles,
    roleGroups,
    selectedRoleGroups,
    users,
    selectedUsers,
  ]);
  const searchPlaceholder = useMemo(() => {
    if (selectedFilter === "hireDate") {
      return "Search...";
    }
    return `Search ${toCamelCase(selectedFilter as string)}...`;
  }, [selectedFilter]);
  const debouncedFetchMore = debounce(() => {
    fetchMore({
      variables: {
        pagination: {
          limit: PAGINATION_LIMIT,
          offset: users.length,
        },
      },
    });
  });
  const handleScroll = useCallback(
    (event: UIEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      const maxScrollValue = target.scrollHeight - target.clientHeight;
      const scrolledPastThreshold = target.scrollTop > maxScrollValue * 0.7;
      if (
        scrolledPastThreshold &&
        selectedFilter === "people" &&
        hasMoreUsers
      ) {
        debouncedFetchMore();
      }
    },
    [selectedFilter, hasMoreUsers, debouncedFetchMore],
  );
  const noUserTypesRoleGroupsOrRolesSelected = useMemo(() => {
    return (
      !props.input.allTrainees &&
      !props.input.allManagers &&
      !props.input.allAdmins &&
      !props.input.roleIds?.length &&
      !props.input.roleGroupIds?.length
    );
  }, [
    props.input.allAdmins,
    props.input.allManagers,
    props.input.allTrainees,
    props.input.roleGroupIds?.length,
    props.input.roleIds?.length,
  ]);
  const allUserTypesSelected = useMemo(() => {
    return !!(
      props.input.allTrainees &&
      props.input.allManagers &&
      props.input.allAdmins
    );
  }, [props.input.allAdmins, props.input.allManagers, props.input.allTrainees]);
  const addUserSelectionFilterOption = useCallback(
    (optionId: number | string) => {
      const newInput = cloneDeep(props.input);
      if (selectedFilter === "locations") {
        const oldLocationIds = newInput.locationIds || [];
        newInput.locationIds = [...oldLocationIds, optionId as number];
        if (noUserTypesRoleGroupsOrRolesSelected) {
          newInput.allTrainees = true;
          newInput.allManagers = true;
          newInput.allAdmins = true;
        }
      } else if (selectedFilter === "roles") {
        const oldRoleIds = newInput.roleIds || [];
        newInput.roleIds = [...oldRoleIds, optionId as number];
        if (allUserTypesSelected) {
          newInput.allTrainees = false;
          newInput.allManagers = false;
          newInput.allAdmins = false;
        }
      } else if (selectedFilter === "departments") {
        const oldRoleGroupIds = newInput.roleGroupIds || [];
        newInput.roleGroupIds = [...oldRoleGroupIds, optionId as string];
        if (allUserTypesSelected) {
          newInput.allTrainees = false;
          newInput.allManagers = false;
          newInput.allAdmins = false;
        }
      } else if (selectedFilter === "people") {
        const oldUserIds = newInput.userIds || [];
        newInput.userIds = [...oldUserIds, optionId as number];
      } else if (isLocationGroupType(selectedFilter)) {
        const oldLocationGroupIds = newInput.locationGroupIds || [];
        newInput.locationGroupIds = [
          ...oldLocationGroupIds,
          optionId as string,
        ];
        if (noUserTypesRoleGroupsOrRolesSelected) {
          newInput.allTrainees = true;
          newInput.allManagers = true;
          newInput.allAdmins = true;
        }
      }
      props.setInput(newInput);
    },
    [
      allUserTypesSelected,
      noUserTypesRoleGroupsOrRolesSelected,
      props,
      selectedFilter,
    ],
  );

  return (
    <AutoLayout
      width={320}
      alignSelf="stretch"
      direction="vertical"
      className={css(styles.leftPanel)}
    >
      <AutoLayout
        padding={12}
        alignSelf="stretch"
        direction="vertical"
        className={css(styles.leftPanelHeader)}
      >
        <TextField
          placeholder={searchPlaceholder}
          text={search}
          leftIcon="search"
          onTextChange={setSearch}
          inputStyleDeclaration={styles.textField}
          disabled={selectedFilter === "hireDate"}
        />
        <AutoLayout
          marginTop={8}
          spaceBetweenItems={8}
          wrap="wrap"
          data-testid={"user-selection-filters"}
        >
          {visibleFilters.map((filter) => {
            const title = toCamelCase(filter as string);
            const color = filter === selectedFilter ? "blue" : "gray";
            return (
              <Pill
                key={title}
                title={title}
                color={color}
                styleDeclaration={styles.pill}
                onClick={() => {
                  setSelectedFilter(filter);
                }}
              />
            );
          })}
        </AutoLayout>
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        alignSelf="stretch"
        onScroll={handleScroll}
        style={{ overflowY: "scroll" }}
      >
        <AutoLayout
          direction="vertical"
          alignSelf="stretch"
          paddingVertical={4}
        >
          {props.showUserTypeOptions && selectedFilter === "roles" && (
            <AutoLayout direction="vertical" alignSelf="stretch">
              {(!props.input.allTrainees || allUserTypesSelected) &&
                !props.hideUserTypes?.includes(UserType.Employee) && (
                  <FilterOption
                    option={{ id: -1, name: "All Trainees" }}
                    onClick={() => {
                      const newInput = cloneDeep(props.input);
                      if (
                        newInput.allTrainees &&
                        newInput.allManagers &&
                        newInput.allAdmins
                      ) {
                        newInput.roleIds = [];
                        newInput.allManagers = false;
                        newInput.allAdmins = false;
                      }
                      newInput.allTrainees = true;
                      if (
                        newInput.allTrainees &&
                        newInput.allManagers &&
                        newInput.allAdmins
                      ) {
                        newInput.roleIds = [];
                      }
                      props.setInput(newInput);
                    }}
                    showSubtitles={false}
                    icon={USER_SELECTION_ICON["roles"]}
                  />
                )}
              {(!props.input.allManagers || allUserTypesSelected) &&
                !props.hideUserTypes?.includes(UserType.Manager) && (
                  <FilterOption
                    option={{ id: -2, name: "All Managers" }}
                    onClick={() => {
                      const newInput = cloneDeep(props.input);
                      if (
                        newInput.allTrainees &&
                        newInput.allManagers &&
                        newInput.allAdmins
                      ) {
                        newInput.roleIds = [];
                        newInput.allAdmins = false;
                        newInput.allTrainees = false;
                      }
                      newInput.allManagers = true;
                      if (
                        newInput.allTrainees &&
                        newInput.allManagers &&
                        newInput.allAdmins
                      ) {
                        newInput.roleIds = [];
                      }
                      props.setInput(newInput);
                    }}
                    showSubtitles={false}
                    icon={USER_SELECTION_ICON["roles"]}
                  />
                )}
              {(!props.input.allAdmins || allUserTypesSelected) &&
                !props.hideUserTypes?.includes(UserType.Admin) && (
                  <FilterOption
                    option={{ id: -3, name: "All Admins" }}
                    onClick={() => {
                      const newInput = cloneDeep(props.input);
                      if (
                        newInput.allTrainees &&
                        newInput.allManagers &&
                        newInput.allAdmins
                      ) {
                        newInput.roleIds = [];
                        newInput.allManagers = false;
                        newInput.allTrainees = false;
                      }
                      newInput.allAdmins = true;
                      if (
                        newInput.allTrainees &&
                        newInput.allManagers &&
                        newInput.allAdmins
                      ) {
                        newInput.roleIds = [];
                      }
                      props.setInput(newInput);
                    }}
                    showSubtitles={false}
                    icon={USER_SELECTION_ICON["roles"]}
                  />
                )}
              {(!allUserTypeFiltersAreSelected || allUserTypesSelected) && (
                <>
                  <Spacer size={8} />
                  <HorizontalDivider />
                  <Spacer size={8} />
                </>
              )}
            </AutoLayout>
          )}
          {visibleUserSelectionFilterOptions.map((option) => {
            return (
              <FilterOption
                key={option.id}
                option={option}
                onClick={addUserSelectionFilterOption}
                showSubtitles={selectedFilter === "people"}
                icon={USER_SELECTION_ICON[selectedFilter]}
              />
            );
          })}
          {selectedFilter === "hireDate" && (
            <div className="gap-1 px-2">
              <Tooltip>
                <TooltipTrigger>
                  <Label className="mb-2 flex items-center gap-1 text-sm">
                    Days after hire
                    <QuestionMarkCircledIcon className="size-4 text-muted-foreground" />
                  </Label>
                </TooltipTrigger>
                <TooltipContent>
                  Filter users who have been at your company for at least a
                  certain number of days. We use hire date from your HRIS or the
                  date the user was added to Opus.
                </TooltipContent>
              </Tooltip>
              <Input
                type="number"
                placeholder=""
                value={props.input.daysAfterHire || ""}
                onChange={(e) => {
                  props.setInput({
                    ...props.input,
                    daysAfterHire: e.target.value
                      ? parseInt(e.target.value)
                      : undefined,
                  });
                }}
              />
            </div>
          )}
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};

type FilterOptionProps = {
  option:
    | SelectUsersModal_UserThinFragment
    | LocationGroupThinFragment
    | LocationThinFragment
    | RoleThinFragment
    | RoleGroupThinFragment;
  showSubtitles: boolean;
  icon: DeprecatedIconType | "avatar";
  onClick: (id: string | number) => void;
};
const FilterOption: FC<FilterOptionProps> = (props) => {
  const IconOrAvatar = useMemo(() => {
    if (props.icon === "avatar") {
      return (
        <Avatar
          size={16}
          color="gray"
          person={{ id: -1, name: props.option.name }}
        />
      );
    } else {
      return (
        <DeprecatedIcon
          type={props.icon}
          iconSize={16}
          color={deprecatedTones.gray6}
        />
      );
    }
  }, [props.icon, props.option.name]);
  const subtitle1 =
    "roles" in props.option && props.option.roles.length > 0
      ? `${props.option.roles[0].name}`
      : "No roles";
  const subtitle2 =
    "locations" in props.option && props.option.locations.length > 0
      ? `${props.option.locations[0].name}`
      : "No locations";

  return (
    <AutoLayout
      key={props.option.id}
      alignSelf="stretch"
      alignmentVertical="center"
      paddingVertical={6}
      paddingHorizontal={12}
      spacingMode="space-between"
      className={cn(css(styles.filterOptionRow), "max-w-[100%]")}
      onClick={() => {
        props.onClick(props.option.id);
      }}
      data-testid={"filter-option"}
    >
      <AutoLayout
        alignmentVertical="center"
        spaceBetweenItems={8}
        flex={1}
        className="max-w-[90%]"
      >
        <AutoLayout
          height={24}
          width={24}
          alignmentHorizontal="center"
          alignmentVertical="center"
          className={css(styles.filterOptionRowIconContainer)}
        >
          {IconOrAvatar}
        </AutoLayout>
        <AutoLayout
          direction="vertical"
          alignSelf="stretch"
          className="w-full truncate"
        >
          <Text type="P2" fontWeight="Medium" ellipsis>
            {props.option.name}
          </Text>
          {props.showSubtitles && (
            <AutoLayout
              alignmentVertical="center"
              spaceBetweenItems={6}
              className="w-full truncate"
            >
              <Text
                type="P3"
                color={deprecatedTones.gray8}
                className="truncate"
              >
                {subtitle1}
              </Text>
              <Circle
                size={3}
                style={{
                  backgroundColor: deprecatedTones.gray6,
                  flexShrink: 0,
                }}
              />
              <Text
                type="P3"
                color={deprecatedTones.gray8}
                className="truncate"
              >
                {subtitle2}
              </Text>
            </AutoLayout>
          )}
        </AutoLayout>
      </AutoLayout>
      <DeprecatedIcon
        type="plus"
        iconSize={14}
        strokeWidth={2}
        color={deprecatedTones.blue9}
        style={{ flexShrink: 0 }}
      />
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  leftPanel: {
    borderRight: `1px solid ${deprecatedTones.gray5Alpha}`,
    overflow: "hidden",
  },
  leftPanelHeader: {
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  filterOptionRow: {
    backgroundColor: deprecatedTones.white,
    transitionDuration: durations.S025,
    transitionProperty: properties.backgroundColor,
    transitionTimingFunction: curves.bezier,
    cursor: "pointer",
    ":hover": {
      backgroundColor: deprecatedTones.gray3Alpha,
    },
  },
  filterOptionRowIconContainer: {
    borderRadius: 8,
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  textField: {
    backgroundColor: deprecatedTones.gray4Alpha,
    border: `1px solid ${deprecatedTones.gray4Alpha}`,
  },
  pill: {
    padding: "4px 10px",
  },
});

gql`
  query SelectUsersModal_Users(
    $input: GetPeopleInput!
    $pagination: PaginationInput
  ) {
    People(input: $input, pagination: $pagination) {
      totalCount
      objects {
        ...SelectUsersModal_UserThin
      }
    }
  }
  fragment SelectUsersModal_UserThin on Employee {
    id
    name
    locations {
      id
      name
    }
    roles {
      id
      name
    }
  }
  query SelectUsersModal_LocationGroups($type: LocationGroupType!) {
    LocationGroups(input: { types: [$type] }) {
      objects {
        ...SelectUsersModal_LocationGroup
      }
    }
  }
  fragment SelectUsersModal_LocationGroup on LocationGroup {
    id
    name
  }
`;

export default SelectUsersModal;
