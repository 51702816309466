export enum PromptErrorType {
  MISSING_TEXT = "MISSING_TEXT",
  MISSING_GRADED_FREE_RESPONSE_ANSWER = "MISSING_GRADED_FREE_RESPONSE_ANSWER",
  MISSING_RESPONSE_OPTION_IMAGE = "MISSING_RESPONSE_OPTION_IMAGE",
  MISSING_RESPONSE_OPTION = "MISSING_RESPONSE_OPTION",
  MISSING_CORRECT_ANSWER = "MISSING_CORRECT_ANSWER",
  MISSING_MATCH_ITEM_IMAGE = "MISSING_MATCH_ITEM_IMAGE",
  MISSING_MATCH_ITEM_TEXT = "MISSING_MATCH_ITEM_TEXT",
}

export const promptErrorTypeMapToLabel: Record<PromptErrorType, string> = {
  [PromptErrorType.MISSING_TEXT]: "Missing text",
  [PromptErrorType.MISSING_GRADED_FREE_RESPONSE_ANSWER]:
    "Missing graded free response answer",
  [PromptErrorType.MISSING_RESPONSE_OPTION_IMAGE]:
    "Missing response option image",
  [PromptErrorType.MISSING_RESPONSE_OPTION]: "Missing response option",
  [PromptErrorType.MISSING_CORRECT_ANSWER]: "Missing correct answer",
  [PromptErrorType.MISSING_MATCH_ITEM_IMAGE]: "Missing match item image",
  [PromptErrorType.MISSING_MATCH_ITEM_TEXT]: "Missing match item text",
};

export type PromptErrorsArray = Array<PromptErrorType>;

export type PromptErrorsMap = Map<string, PromptErrorsArray>;

export type CourseValidationErrors = {
  hasPendingScormGeneration: boolean;
  hasPendingAiGeneration: boolean;
  promptErrors: PromptErrorsMap;
  emptyLessons: Set<string>;
};

export const emptyCourseValidationErrors = (): CourseValidationErrors => {
  return {
    hasPendingScormGeneration: false,
    hasPendingAiGeneration: false,
    promptErrors: new Map(),
    emptyLessons: new Set(),
  };
};
