import gql from "graphql-tag";
import usePathBuilderContentPermission from "@src/components/libraryItemDetailPages/module/hooks/usePathBuilderContentPermission";
import { BuilderActionContentIds } from "@contexts/BuilderActionContext";
import {
  ContentPermissionsFragment,
  useContentPermissionsQuery,
} from "@src/components/libraryItemDetailPages/hooks/useContentPermissions.generated";
import { useMemo } from "react";

export const ALL_FALSE_CONTENT_PERMISSIONS: ContentPermissionsFragment = {
  hasEditContentPermission: false,
  hasPublishPermission: false,
  hasCommentPermission: false,
  hasShareWithIndividualsPermission: false,
  hasEditAdminAndManagerSharingPermission: false,
};

const useContentPermissions = (
  args: BuilderActionContentIds,
): ContentPermissionsFragment | undefined => {
  const pathBuilderVersionPermissions = usePathBuilderContentPermission();
  const { data } = useContentPermissionsQuery({
    variables: {
      courseId: args.courseId,
      pathId: args.pathId,
      skillId: args.skillId,
      trainingResourceId: args.trainingResourceId,
    },
    skip:
      !!pathBuilderVersionPermissions ||
      (!args.courseId &&
        !args.pathId &&
        !args.skillId &&
        !args.trainingResourceId),
    fetchPolicy: "cache-first",
  });
  const result = useMemo(
    () =>
      pathBuilderVersionPermissions || data?.ContentPermissions || undefined,
    [data?.ContentPermissions, pathBuilderVersionPermissions],
  );
  return result;
};

export default useContentPermissions;

gql`
  query ContentPermissions(
    $courseId: Int
    $pathId: Int
    $skillId: Int
    $trainingResourceId: UUID
  ) {
    ContentPermissions: AdminContentPermissions(
      courseId: $courseId
      pathId: $pathId
      skillId: $skillId
      trainingResourceId: $trainingResourceId
    ) {
      ...ContentPermissions
    }
  }

  fragment ContentPermissions on ContentPermissions {
    hasEditContentPermission
    hasPublishPermission
    hasCommentPermission
    hasShareWithIndividualsPermission
    hasEditAdminAndManagerSharingPermission
  }
`;
