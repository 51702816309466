import {
  PromptErrorType,
  CourseValidationErrors,
} from "@src/components/libraryItemDetailPages/course/types/validation";
import { PromptType } from "@src/types.generated";
import { TranslationSetFragment } from "@src/fragments.generated";
import { isRichTextEmpty } from "@src/components/libraryItemDetailPages/course/utils/utils";
import { hasPendingScormGeneration } from "@src/components/libraryItemDetailPages/course/hooks/useMaybePollCourseVersion";
import { hasIncompleteAIGeneration } from "@src/components/libraryItemDetailPages/course/hooks/useMaybePollCourseVersion";
import {
  CourseContentCourseVersionFragment,
  CourseContentPromptFragment,
  ResponseOptionFragment,
} from "../build/CourseBuildContent.generated";

type Return = {
  earliestUuidWithError: string | null;
  validationErrors: CourseValidationErrors;
};

export const validateDraftCourse = (
  cv: CourseContentCourseVersionFragment,
): Return => {
  const promptErrors = new Map<string, Array<PromptErrorType>>();
  const emptyLessons: Set<string> = new Set();
  let earliestUuidWithError: string | null = null;
  cv.lessons.forEach((lesson) => {
    if (lesson) {
      if (lesson.prompts.length === 0) {
        emptyLessons.add(lesson.uuid);
        if (!earliestUuidWithError) {
          earliestUuidWithError = lesson.uuid;
        }
      }
      lesson.prompts.forEach((prompt) => {
        if (prompt) {
          const promptErrorTypes = getPromptErrorTypes(prompt);
          if (promptErrorTypes.length > 0) {
            if (!earliestUuidWithError) {
              earliestUuidWithError = prompt.uuid;
            }
            promptErrors.set(prompt.uuid, promptErrorTypes);
          }
        }
      });
    }
  });
  return {
    earliestUuidWithError,
    validationErrors: {
      hasPendingScormGeneration: hasPendingScormGeneration(cv),
      hasPendingAiGeneration: hasIncompleteAIGeneration(cv),
      promptErrors,
      emptyLessons,
    },
  };
};

const getPromptErrorTypes = (
  prompt: CourseContentPromptFragment,
): Array<PromptErrorType> => {
  const promptErrors = new Array<PromptErrorType>();
  if (!promptHasText(prompt)) {
    promptErrors.push(PromptErrorType.MISSING_TEXT);
  }
  if (
    prompt.type === PromptType.GradedFreeResponse &&
    !promptHasGradedFreeResponseAnswer(prompt)
  ) {
    promptErrors.push(PromptErrorType.MISSING_GRADED_FREE_RESPONSE_ANSWER);
  }
  if (
    prompt.type === PromptType.TrueFalse &&
    !(prompt.correctTfAnswer === true || prompt.correctTfAnswer === false)
  ) {
    promptErrors.push(PromptErrorType.MISSING_CORRECT_ANSWER);
  }
  if (prompt.type === PromptType.MultipleChoice) {
    prompt.responseOptions.forEach((ro) => {
      if (!translationSetHasEnglishText(ro.answer)) {
        promptErrors.push(PromptErrorType.MISSING_RESPONSE_OPTION);
      }
    });
    if (!prompt.responseOptions.find((ro) => ro.isCorrect)) {
      promptErrors.push(PromptErrorType.MISSING_CORRECT_ANSWER);
    }
  }
  if (prompt.type === PromptType.ImageChoice) {
    prompt.responseOptions.forEach((ro) => {
      if (!translationSetHasEnglishText(ro.answer)) {
        promptErrors.push(PromptErrorType.MISSING_RESPONSE_OPTION);
      }
      if (!responseOptionHasImage(ro)) {
        promptErrors.push(PromptErrorType.MISSING_RESPONSE_OPTION_IMAGE);
      }
    });
    if (!prompt.responseOptions.find((ro) => ro.isCorrect)) {
      promptErrors.push(PromptErrorType.MISSING_CORRECT_ANSWER);
    }
  }
  if (prompt.type === PromptType.Matching) {
    prompt.responseOptions.forEach((ro) => {
      if (!translationSetHasEnglishText(ro.answer)) {
        promptErrors.push(PromptErrorType.MISSING_RESPONSE_OPTION);
      }
      if (prompt.imageOnlyMatchItems && !ro.matchItem?.mediaId) {
        promptErrors.push(PromptErrorType.MISSING_MATCH_ITEM_IMAGE);
      }
      if (
        !prompt.imageOnlyMatchItems &&
        (!ro.matchItem?.text ||
          !translationSetHasEnglishText(ro.matchItem.text))
      ) {
        promptErrors.push(PromptErrorType.MISSING_MATCH_ITEM_TEXT);
      }
    });
  } else if (prompt.type === PromptType.Ordering) {
    prompt.responseOptions.forEach((ro) => {
      if (!translationSetHasEnglishText(ro.answer)) {
        promptErrors.push(PromptErrorType.MISSING_RESPONSE_OPTION);
      }
    });
  }
  return promptErrors;
};

export const promptHasText = (prompt: CourseContentPromptFragment): boolean => {
  return !!prompt.text && translationSetHasEnglishText(prompt.text);
};

const promptHasGradedFreeResponseAnswer = (
  prompt: CourseContentPromptFragment,
): boolean => {
  return (
    !!prompt.correctFreeResponseAnswer &&
    translationSetHasEnglishText(prompt.correctFreeResponseAnswer)
  );
};

export const translationSetHasEnglishText = (
  ts: TranslationSetFragment,
): boolean => {
  if (!ts) {
    return false;
  }
  return !isRichTextEmpty(ts["en"]);
};

export const courseHasValidationErrors = (
  validationErrors: CourseValidationErrors,
): boolean => {
  return courseValidationErrorCount(validationErrors) > 0;
};

export const courseValidationErrorCount = (
  validationErrors: CourseValidationErrors,
): number => {
  return (
    validationErrors.emptyLessons.size +
    Array.from(validationErrors.promptErrors.values()).reduce(
      (acc, cur) => acc + cur.length,
      0,
    ) +
    (validationErrors.hasPendingScormGeneration ? 1 : 0) +
    (validationErrors.hasPendingAiGeneration ? 1 : 0)
  );
};

export const responseOptionHasImage = (
  responseOption: ResponseOptionFragment,
): boolean => {
  return Boolean(responseOption.mediaId);
};
