import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { CoverImageFragmentDoc, MediaFragmentDoc, TrainingResourceVersionFragmentDoc, ContentSharingPermissionFragmentDoc, TranslationSetFragmentDoc, ContentTagMembershipFragmentDoc, ImageUrlsFragmentDoc, AutomationFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, ConvertMediaToRichTextDocumentWorkflowFragmentDoc, BlastSentRecordFragmentDoc, TrainingResourceFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewAutomationCourseQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type NewAutomationCourseQuery = { __typename?: 'Query', AdminCourse?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null, name: { __typename?: 'TranslationSet', en: string } } } | null };

export type NewAutomationTrainingResourceQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type NewAutomationTrainingResourceQuery = { __typename?: 'Query', AdminTrainingResource?: { __typename?: 'TrainingResource', id: string, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string } }, publishedVersion?: { __typename?: 'TrainingResourceVersion', id: string, media?: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResourceVersions?: Array<{ __typename?: 'TrainingResourceVersion', id: string, trainingResource: { __typename?: 'TrainingResource', id: string } }> | null } | null } | null } | null };

export type NewAutomationPathQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type NewAutomationPathQuery = { __typename?: 'Query', AdminPath?: { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', en: string }, coverImage?: { __typename?: 'CoverImage', id: string, emojiIcon?: string | null, background?: Types.BackgroundGradient | null, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null } | null } } | null };


export const NewAutomationCourseDocument = gql`
    query NewAutomationCourse($id: Int!) {
  AdminCourse(id: $id) {
    id
    libraryItem {
      id
      coverImage {
        ...CoverImage
      }
      name {
        en
      }
    }
  }
}
    ${CoverImageFragmentDoc}`;

/**
 * __useNewAutomationCourseQuery__
 *
 * To run a query within a React component, call `useNewAutomationCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewAutomationCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewAutomationCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewAutomationCourseQuery(baseOptions: Apollo.QueryHookOptions<NewAutomationCourseQuery, NewAutomationCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewAutomationCourseQuery, NewAutomationCourseQueryVariables>(NewAutomationCourseDocument, options);
      }
export function useNewAutomationCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewAutomationCourseQuery, NewAutomationCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewAutomationCourseQuery, NewAutomationCourseQueryVariables>(NewAutomationCourseDocument, options);
        }
export type NewAutomationCourseQueryHookResult = ReturnType<typeof useNewAutomationCourseQuery>;
export type NewAutomationCourseLazyQueryHookResult = ReturnType<typeof useNewAutomationCourseLazyQuery>;
export type NewAutomationCourseQueryResult = Apollo.QueryResult<NewAutomationCourseQuery, NewAutomationCourseQueryVariables>;
export const NewAutomationTrainingResourceDocument = gql`
    query NewAutomationTrainingResource($id: String!) {
  AdminTrainingResource(id: $id) {
    id
    libraryItem {
      id
      name {
        en
      }
    }
    publishedVersion {
      id
      media {
        ...Media
      }
    }
  }
}
    ${MediaFragmentDoc}`;

/**
 * __useNewAutomationTrainingResourceQuery__
 *
 * To run a query within a React component, call `useNewAutomationTrainingResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewAutomationTrainingResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewAutomationTrainingResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewAutomationTrainingResourceQuery(baseOptions: Apollo.QueryHookOptions<NewAutomationTrainingResourceQuery, NewAutomationTrainingResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewAutomationTrainingResourceQuery, NewAutomationTrainingResourceQueryVariables>(NewAutomationTrainingResourceDocument, options);
      }
export function useNewAutomationTrainingResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewAutomationTrainingResourceQuery, NewAutomationTrainingResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewAutomationTrainingResourceQuery, NewAutomationTrainingResourceQueryVariables>(NewAutomationTrainingResourceDocument, options);
        }
export type NewAutomationTrainingResourceQueryHookResult = ReturnType<typeof useNewAutomationTrainingResourceQuery>;
export type NewAutomationTrainingResourceLazyQueryHookResult = ReturnType<typeof useNewAutomationTrainingResourceLazyQuery>;
export type NewAutomationTrainingResourceQueryResult = Apollo.QueryResult<NewAutomationTrainingResourceQuery, NewAutomationTrainingResourceQueryVariables>;
export const NewAutomationPathDocument = gql`
    query NewAutomationPath($id: Int!) {
  AdminPath(id: $id) {
    id
    libraryItem {
      id
      name {
        en
      }
      coverImage {
        ...CoverImage
      }
    }
  }
}
    ${CoverImageFragmentDoc}`;

/**
 * __useNewAutomationPathQuery__
 *
 * To run a query within a React component, call `useNewAutomationPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewAutomationPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewAutomationPathQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewAutomationPathQuery(baseOptions: Apollo.QueryHookOptions<NewAutomationPathQuery, NewAutomationPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewAutomationPathQuery, NewAutomationPathQueryVariables>(NewAutomationPathDocument, options);
      }
export function useNewAutomationPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewAutomationPathQuery, NewAutomationPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewAutomationPathQuery, NewAutomationPathQueryVariables>(NewAutomationPathDocument, options);
        }
export type NewAutomationPathQueryHookResult = ReturnType<typeof useNewAutomationPathQuery>;
export type NewAutomationPathLazyQueryHookResult = ReturnType<typeof useNewAutomationPathLazyQuery>;
export type NewAutomationPathQueryResult = Apollo.QueryResult<NewAutomationPathQuery, NewAutomationPathQueryVariables>;