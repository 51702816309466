import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContentPermissionsQueryVariables = Types.Exact<{
  courseId?: Types.InputMaybe<Types.Scalars['Int']>;
  pathId?: Types.InputMaybe<Types.Scalars['Int']>;
  skillId?: Types.InputMaybe<Types.Scalars['Int']>;
  trainingResourceId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;


export type ContentPermissionsQuery = { __typename?: 'Query', ContentPermissions?: { __typename?: 'ContentPermissions', hasEditContentPermission: boolean, hasPublishPermission: boolean, hasCommentPermission: boolean, hasShareWithIndividualsPermission: boolean, hasEditAdminAndManagerSharingPermission: boolean } | null };

export type ContentPermissionsFragment = { __typename?: 'ContentPermissions', hasEditContentPermission: boolean, hasPublishPermission: boolean, hasCommentPermission: boolean, hasShareWithIndividualsPermission: boolean, hasEditAdminAndManagerSharingPermission: boolean };

export const ContentPermissionsFragmentDoc = gql`
    fragment ContentPermissions on ContentPermissions {
  hasEditContentPermission
  hasPublishPermission
  hasCommentPermission
  hasShareWithIndividualsPermission
  hasEditAdminAndManagerSharingPermission
}
    `;
export const ContentPermissionsDocument = gql`
    query ContentPermissions($courseId: Int, $pathId: Int, $skillId: Int, $trainingResourceId: UUID) {
  ContentPermissions: AdminContentPermissions(
    courseId: $courseId
    pathId: $pathId
    skillId: $skillId
    trainingResourceId: $trainingResourceId
  ) {
    ...ContentPermissions
  }
}
    ${ContentPermissionsFragmentDoc}`;

/**
 * __useContentPermissionsQuery__
 *
 * To run a query within a React component, call `useContentPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPermissionsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      pathId: // value for 'pathId'
 *      skillId: // value for 'skillId'
 *      trainingResourceId: // value for 'trainingResourceId'
 *   },
 * });
 */
export function useContentPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<ContentPermissionsQuery, ContentPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentPermissionsQuery, ContentPermissionsQueryVariables>(ContentPermissionsDocument, options);
      }
export function useContentPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentPermissionsQuery, ContentPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentPermissionsQuery, ContentPermissionsQueryVariables>(ContentPermissionsDocument, options);
        }
export type ContentPermissionsQueryHookResult = ReturnType<typeof useContentPermissionsQuery>;
export type ContentPermissionsLazyQueryHookResult = ReturnType<typeof useContentPermissionsLazyQuery>;
export type ContentPermissionsQueryResult = Apollo.QueryResult<ContentPermissionsQuery, ContentPermissionsQueryVariables>;