import * as React from "react";
import { IconProps } from "../types";

const LightbulbOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M6.75 13.25H11.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 6.75C14 3.63695 11.154 1.18795 7.92201 1.86295C5.99001 2.26595 4.44701 3.85595 4.08801 5.79595C3.65401 8.13995 4.85901 10.2549 6.75001 11.2109V14.2499C6.75001 15.3549 7.64501 16.2499 8.75001 16.2499H9.25001C10.355 16.2499 11.25 15.3549 11.25 14.2499V11.2109C12.88 10.3869 14 8.70195 14 6.74995Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default LightbulbOutlineIcon;
