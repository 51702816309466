import { FC, useCallback, useMemo } from "react";
import { css, StyleSheet } from "aphrodite";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import Text from "@ui/text";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import SelectUsersModal from "./SelectUsersModal";
import {
  getAutomationItemContentType,
  getContentIdFromAutomationItem,
} from "./utils";
import { AutomationItem } from "@src/components/sidebars/shared/constants";
import { useModal } from "@src/hooks/useModal";
import {
  AutomationType,
  CoverImage,
  UserSelectionInput,
  UserType,
} from "@src/types.generated";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { gql } from "graphql-request";
import useUserSelectionInputState from "./hooks/useUserSelectionInputState";
import useReviewAutomationState from "./hooks/useReviewAutomationState";
import {
  useNewAutomationCourseQuery,
  useNewAutomationPathQuery,
  useNewAutomationTrainingResourceQuery,
} from "./AutomationModal.generated";
import useGetUserSelectionInputFromAutomationItem from "./hooks/useGetUserSelectionInputFromAutomationItem";
import { launchConfetti } from "@src/utils/confetti";
import { useToast } from "@src/hooks/useToast";
import AutomationUserSelectionPreviewButton from "./AutomationUserSelectionPreviewButton";
import { useUpdateAutomationMutation } from "@src/components/contentDetail/premiumPathDetail/AutoReassignToggle.generated";
import { OrgPremiumContentConfigFragment } from "@src/components/library/LibraryPaginatedTable.generated";

type Props = {
  automationItem: AutomationItem;
  orgConfig?: OrgPremiumContentConfigFragment;
  existingAutomation?: boolean;
  refetchQueries?: string[];
  hideSuccessToast?: boolean;
  warnOnNoLocationsSelectedText?: string;
};

const NEW_COPY = {
  [AutomationType.LibraryAccess]: "Library Access",
  [AutomationType.Assignment]: "New automation",
};

const EDIT_COPY = {
  [AutomationType.LibraryAccess]: "Library Access",
  [AutomationType.Assignment]: "Edit automation",
};

const CREATE_COPY = {
  [AutomationType.LibraryAccess]: "Save",
  [AutomationType.Assignment]: "Create",
};

const USER_SELECTION_TITLE_COPY = {
  [AutomationType.LibraryAccess]: "Access rules",
  [AutomationType.Assignment]: "Automation rules",
};

const USER_SELECTION_PLACEHOLDER_COPY = {
  [AutomationType.LibraryAccess]: "Select automation rules",
  [AutomationType.Assignment]: "Select users",
};

const AutomationModal: FC<Props> = ({
  refetchQueries = [],
  existingAutomation = false,
  ...props
}) => {
  const { showModal, closeModal } = useModal();
  const initialInput = useGetUserSelectionInputFromAutomationItem(
    props.automationItem,
  );
  const { userSelectionInput, setUserSelectionInput } =
    useUserSelectionInputState({
      input: initialInput,
      allowUserTypes: true,
    });
  const { data: pathData } = useNewAutomationPathQuery({
    skip: !props.automationItem.automation.libraryItem?.path?.id,
    variables: {
      id: props.automationItem.automation.libraryItem?.path?.id || -1,
    },
  });
  const { data: courseData } = useNewAutomationCourseQuery({
    skip: !props.automationItem.automation.libraryItem?.course?.id,
    variables: {
      id: props.automationItem.automation.libraryItem?.course?.id || -1,
    },
  });
  const { data: trainingResourceData } = useNewAutomationTrainingResourceQuery({
    skip: !props.automationItem.automation.libraryItem?.trainingResource?.id,
    variables: {
      id:
        props.automationItem.automation.libraryItem?.trainingResource?.id || "",
    },
  });
  const contentCoverImage: CoverImage | undefined = useMemo(() => {
    if (props.automationItem.automation.libraryItem?.path?.id) {
      return pathData?.AdminPath?.libraryItem.coverImage || undefined;
    } else if (props.automationItem.automation.libraryItem?.course?.id) {
      return courseData?.AdminCourse?.libraryItem.coverImage || undefined;
    } else if (
      props.automationItem.automation.libraryItem?.trainingResource?.id
    ) {
      return {
        id: props.automationItem.automation.libraryItem?.trainingResource?.id,
        imageUrls:
          trainingResourceData?.AdminTrainingResource?.publishedVersion?.media
            ?.imageUrls,
      };
    } else {
      return {} as CoverImage;
    }
  }, [
    props.automationItem.automation.libraryItem?.path?.id,
    props.automationItem.automation.libraryItem?.course?.id,
    props.automationItem.automation.libraryItem?.trainingResource?.id,
    pathData?.AdminPath?.libraryItem.coverImage,
    courseData?.AdminCourse?.libraryItem.coverImage,
    trainingResourceData?.AdminTrainingResource?.publishedVersion?.media
      ?.imageUrls,
  ]);
  const automationType = props.automationItem.automation.type;
  const {
    createAutomation,
    createAutomationLoading,
    reviewAutomationDetails,
    userSelectionValidationError,
    clearUserSelectionValidationError,
  } = useReviewAutomationState({
    automationItem: props.automationItem,
    userSelectionInput,
    refetchQueries,
    createAutomationCompleted: closeModal,
    orgConfig: props.orgConfig,
    hideSuccessToast: props.hideSuccessToast,
    warnOnNoLocationsSelectedText: props.warnOnNoLocationsSelectedText,
  });
  const { addToast, addErrorToast } = useToast();
  const [updateAccess, { loading: accessLoading }] =
    useUpdateAutomationMutation({
      onCompleted: (data) => {
        if (data.updateAutomation.success) {
          launchConfetti();
          addToast({
            message: "Library Access updated",
          });
        } else {
          addErrorToast({
            ...data,
            callsite: "automation_modal",
          });
        }
      },
    });
  const modalHeader = useMemo(() => {
    const element = (
      <Text type="P2" fontWeight="SemiBold">
        {existingAutomation
          ? EDIT_COPY[automationType]
          : NEW_COPY[automationType]}
      </Text>
    );
    return <TitleHeader element={element} onCancelClick={closeModal} />;
  }, [closeModal, existingAutomation, automationType]);
  const handleOnSelect = useCallback(
    (newInput: UserSelectionInput) => {
      setUserSelectionInput(newInput);
    },
    [setUserSelectionInput],
  );
  const handleAccessChange = useCallback(
    (newInput: UserSelectionInput) => {
      updateAccess({
        variables: {
          id: props.automationItem.automation.id,
          input: { userSelection: newInput },
        },
      });
    },
    [updateAccess, props.automationItem.automation.id],
  );
  const footerLeftContent = useMemo(() => {
    return (
      <Text
        type="P3"
        fontWeight="Medium"
        color={deprecatedTones.red9}
        multiline
      >
        {userSelectionValidationError}
      </Text>
    );
  }, [userSelectionValidationError]);
  const modalFooter = useMemo(() => {
    const primaryButton = (
      <Button
        text={existingAutomation ? "Save" : CREATE_COPY[automationType]}
        data-testid="assign-button"
        onClick={createAutomation}
        disabled={createAutomationLoading}
        loading={createAutomationLoading}
        height="40px"
      />
    );
    return (
      <Footer
        leftContent={footerLeftContent}
        primaryButton={primaryButton}
        isSaveLoading={createAutomationLoading}
        cancelTitle="Cancel"
        onCancelClick={closeModal}
      />
    );
  }, [
    closeModal,
    createAutomation,
    createAutomationLoading,
    existingAutomation,
    footerLeftContent,
    automationType,
  ]);
  const showSelectUsersModal = useCallback(() => {
    clearUserSelectionValidationError();
    showModal(
      <SelectUsersModal
        input={userSelectionInput}
        onSelect={handleOnSelect}
        hideFilters={["people"]}
        pillColor="purple"
        title={USER_SELECTION_TITLE_COPY[automationType]}
        showUserTypeOptions={true}
      />,
    );
  }, [
    clearUserSelectionValidationError,
    handleOnSelect,
    showModal,
    userSelectionInput,
    automationType,
  ]);

  return automationType === AutomationType.Assignment ? (
    <Modal
      width={480}
      header={modalHeader}
      footer={modalFooter}
      dataTestId={"automation-modal"}
    >
      <AutoLayout
        padding={24}
        minHeight={240}
        direction="vertical"
        spaceBetweenItems={24}
        className={css(styles.modalContent)}
      >
        <AutoLayout
          spaceBetweenItems={12}
          alignmentVertical="center"
          alignSelf="stretch"
        >
          <ContentAvatar
            style={{ borderRadius: 8, zIndex: 0 }}
            contentType={getAutomationItemContentType(
              props.automationItem.__typename,
            )}
            coverImage={contentCoverImage}
            media={
              trainingResourceData?.AdminTrainingResource?.publishedVersion
                ?.media
            }
            defaultBackgroundHashKey={getContentIdFromAutomationItem(
              props.automationItem,
            )}
          />
          <AutoLayout direction="vertical" spaceBetweenItems={2} flex={1}>
            <Text type="P1" fontWeight="SemiBold" ellipsis>
              {props.automationItem.contentName}
            </Text>
            <Text type="P3" fontWeight="Medium" color={deprecatedTones.gray7}>
              {getAutomationItemContentType(props.automationItem.__typename)}
            </Text>
          </AutoLayout>
        </AutoLayout>
        <AutomationUserSelectionPreviewButton
          onClick={showSelectUsersModal}
          input={userSelectionInput}
          label={USER_SELECTION_TITLE_COPY[automationType]}
          placeholder={USER_SELECTION_PLACEHOLDER_COPY[automationType]}
        />
        {reviewAutomationDetails}
      </AutoLayout>
    </Modal>
  ) : !accessLoading ? (
    <SelectUsersModal
      input={userSelectionInput}
      onSelect={handleAccessChange}
      hideFilters={["people"]}
      pillColor="purple"
      title={USER_SELECTION_TITLE_COPY[automationType]}
      showUserTypeOptions={true}
      hideUserTypes={[UserType.Admin]}
    />
  ) : null;
};

const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: deprecatedTones.white,
  },
  creditConfirmationBanner: {
    backgroundColor: deprecatedTones.green1Alpha,
    border: `1px solid ${deprecatedTones.green5Alpha}`,
  },
});

gql`
  query NewAutomationCourse($id: Int!) {
    AdminCourse(id: $id) {
      id
      libraryItem {
        id
        coverImage {
          ...CoverImage
        }
        name {
          en
        }
      }
    }
  }
  query NewAutomationTrainingResource($id: String!) {
    AdminTrainingResource(id: $id) {
      id
      libraryItem {
        id
        name {
          en
        }
      }
      publishedVersion {
        id
        media {
          ...Media
        }
      }
    }
  }
  query NewAutomationPath($id: Int!) {
    AdminPath(id: $id) {
      id
      libraryItem {
        id
        name {
          en
        }
        coverImage {
          ...CoverImage
        }
      }
    }
  }
`;

export default AutomationModal;
