import { useUploadFileListMutation } from "@hooks/useFileListUpload.generated";
import { MediaFragment } from "@hooks/useFileUpload";
import { useToast } from "@hooks/useToast";
import { validateFileType } from "@utils/files";
import gql from "graphql-tag";
import { useCallback, useState } from "react";

export const useFileListUpload = (
  onFileListUploadSuccess: (mediaFiles: MediaFragment[]) => void,
): [(files: File[] | FileList) => void, { loading: boolean }] => {
  const { addErrorToast } = useToast();
  const [inputFileNames, setInputFileNames] = useState<string[]>([]);
  const [uploadFileList, { loading }] = useUploadFileListMutation({
    onCompleted: (data) => {
      if (data.uploadFileList.success && data.uploadFileList.fileUrls) {
        const mediaFiles: MediaFragment[] = data.uploadFileList.fileUrls.map(
          (fileUrl, i) => ({
            url: fileUrl,
            name: inputFileNames[i],
          }),
        );
        onFileListUploadSuccess(mediaFiles);
      } else {
        addErrorToast({
          ...data.uploadFileList.error,
          callsite: "upload_file_list",
        });
      }
    },
    onError: (error) => {
      addErrorToast({
        ...error,
        callsite: "upload_file_list",
      });
    },
  });
  const handleFileListEvent = useCallback(
    (files: File[] | FileList) => {
      if (files.length === 0) {
        return;
      }

      const validFiles = [...files].filter(validateFileType);
      if (validFiles.length === 0) {
        addErrorToast({
          message: "Please try again. An invalid file type was uploaded.",
          callsite: "upload_file_list",
        });
        return;
      }

      const fileNames = validFiles.map((file) => file.name);
      setInputFileNames(fileNames);
      // @ts-ignore
      uploadFileList({ variables: { files: validFiles } });
    },
    [uploadFileList, setInputFileNames, addErrorToast],
  );
  return [handleFileListEvent, { loading }];
};

gql`
  mutation UploadFileList($files: [Upload!]!) {
    uploadFileList(mediaFiles: $files) {
      success
      error {
        code
      }
      fileUrls
    }
  }
`;
