import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { PathAssignmentTable_AssignmentFragmentDoc } from '../../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModuleManageAssignmentsTab_AssignmentsQueryVariables = Types.Exact<{
  input: Types.AssignmentsInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type ModuleManageAssignmentsTab_AssignmentsQuery = { __typename?: 'Query', Assignments: { __typename?: 'Assignments', totalCount: number, objects: Array<{ __typename?: 'Assignment', id: string, createdAt: string, firstAssignedAt?: string | null, currentInstanceFirstAssignedAt?: string | null, currentInstanceCompletedAt?: string | null, status: AssignmentStatus, assignedByList?: Array<string> | null, user: { __typename?: 'Employee', id: number, name: string, firstName: string, lastName: string, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }>, hrisEmployee?: { __typename?: 'HrisEmployee', id: number, startDate?: string | null } | null }, pathInstances: Array<{ __typename?: 'PathInstance', id: string, startedAt?: string | null, completedAt?: string | null, dueDate?: string | null, status: AttemptStatus, certificateUrl?: string | null }>, currentPathInstance?: { __typename?: 'PathInstance', id: string, createdAt: string, pathVersion?: { __typename?: 'PathVersion', id: string, metadata: { __typename?: 'VersionMetadata', publishedAt?: string | null } } | null } | null }> } };


export const ModuleManageAssignmentsTab_AssignmentsDocument = gql`
    query ModuleManageAssignmentsTab_Assignments($input: AssignmentsInput!, $pagination: PaginationInput) {
  Assignments(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...PathAssignmentTable_Assignment
    }
  }
}
    ${PathAssignmentTable_AssignmentFragmentDoc}`;

/**
 * __useModuleManageAssignmentsTab_AssignmentsQuery__
 *
 * To run a query within a React component, call `useModuleManageAssignmentsTab_AssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleManageAssignmentsTab_AssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleManageAssignmentsTab_AssignmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useModuleManageAssignmentsTab_AssignmentsQuery(baseOptions: Apollo.QueryHookOptions<ModuleManageAssignmentsTab_AssignmentsQuery, ModuleManageAssignmentsTab_AssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleManageAssignmentsTab_AssignmentsQuery, ModuleManageAssignmentsTab_AssignmentsQueryVariables>(ModuleManageAssignmentsTab_AssignmentsDocument, options);
      }
export function useModuleManageAssignmentsTab_AssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleManageAssignmentsTab_AssignmentsQuery, ModuleManageAssignmentsTab_AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleManageAssignmentsTab_AssignmentsQuery, ModuleManageAssignmentsTab_AssignmentsQueryVariables>(ModuleManageAssignmentsTab_AssignmentsDocument, options);
        }
export type ModuleManageAssignmentsTab_AssignmentsQueryHookResult = ReturnType<typeof useModuleManageAssignmentsTab_AssignmentsQuery>;
export type ModuleManageAssignmentsTab_AssignmentsLazyQueryHookResult = ReturnType<typeof useModuleManageAssignmentsTab_AssignmentsLazyQuery>;
export type ModuleManageAssignmentsTab_AssignmentsQueryResult = Apollo.QueryResult<ModuleManageAssignmentsTab_AssignmentsQuery, ModuleManageAssignmentsTab_AssignmentsQueryVariables>;